import React, { useEffect, useState } from "react";
import Aside from "./components/Aside";
import "../../assets/scss/style.scss";
import "../../assets/scss/color.scss";
import { Col, Input, Label, Row } from "reactstrap";
import { api } from "../../utils/api";
import toast from "react-hot-toast";

const Ayarlar = () => {
  const [data, setData] = useState({});
  const [password, setPassword] = useState({
    password: "",
    passwordconfrim: "",
    oldpassword: "",
  });
  const getUserData = () => {
    api()
      .get("profile-get")
      .then((res) => {
        const newData = res.data.data[0];
        delete newData.password;
        delete newData.jwttoken;
        setData(newData);
        console.log(newData);
      });
  };
  useEffect(() => {
    getUserData();
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(data);
    api()
      .post("profile-edit", data)
      .then(() => {
        const message = "Başarıyla Güncellendi!";
        toast.success(message);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  };
  const handlePasswordChange = (e) => {
    e.preventDefault();
    console.log(password);
    if (password.password !== password.passwordconfrim) {
      toast.error("Şifreler aynı değil");
      return false;
    }
    api()
      .post("profile-edit", data)
      .then(() => {
        const message = "Başarıyla Güncellendi!";
        toast.success(message);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  };
  return (
    <div className="p-0 m-0 overflow-hidden">
      <div className="row" style={{ padding: "50px 80px" }}>
        <div className="col-lg-3">
          <Aside />
        </div>
        <div className="col-lg-9 col-12 mt-lg-0 mt-4">
          <div className="hesap-ozetim-container">
            <div className="d-flex align-items-center gap-1">
              <p className="m-0 p-0 fs-20 fw-600">Hesap Ayarları</p>
            </div>
            <Row tag={"form"} onSubmit={handleSubmit} className="mt-4">
              <Col lg={6} className="mb-4">
                <Label for="ad">Ad Soyad</Label>
                <Input
                  type="text"
                  placeholder="Ad Soyad"
                  id="ad"
                  className="form-control hesap-ayarlari-input"
                  value={data.namesurname}
                  onChange={(e) =>
                    setData((val) => ({
                      ...val,
                      namesurname: e.target.value.replace(
                        /[^a-zA-ZığüşöçĞÜŞİÖÇ\s]/g,
                        ""
                      ),
                    }))
                  }
                />
              </Col>
              <Col lg={6} className="mb-4">
                <Label for="email">E-Posta</Label>
                <Input
                  type="text"
                  placeholder="email"
                  id="email"
                  className="form-control hesap-ayarlari-input"
                  value={data.email}
                  onChange={(e) =>
                    setData((val) => ({
                      ...val,
                      email: e.target.value,
                    }))
                  }
                />
              </Col>
              <Col lg={6} className="mb-4">
                <Label for="cinsiyet">Cinsiyet</Label>
                <Input
                  type="text"
                  placeholder="Cinsiyet"
                  id="cinsiyet"
                  className="form-control hesap-ayarlari-input"
                  value={data.gender}
                  onChange={(e) =>
                    setData((val) => ({
                      ...val,
                      gender: e.target.value.replace(
                        /[^a-zA-ZığüşöçĞÜŞİÖÇ\s]/g,
                        ""
                      ),
                    }))
                  }
                />
              </Col>
              <Col lg={6} className="mb-4">
                <Label for="telefon">Telefon</Label>
                <Input
                  type="number"
                  placeholder="Telefon"
                  id="telefon"
                  className="form-control hesap-ayarlari-input"
                  value={data.gsm}
                  onChange={(e) =>
                    setData((val) => ({
                      ...val,
                      gsm: e.target.value.replace(/[^0-9]/g, ""),
                    }))
                  }
                />
              </Col>
              <Col lg={6} className="mb-4">
                <Label for="kan-grubu">Kan Grubu</Label>
                <Input
                  type="text"
                  placeholder="Kan Grubu"
                  id="kan-grubu"
                  className="form-control hesap-ayarlari-input"
                  value={data.blood_type}
                  onChange={(e) =>
                    setData((val) => ({
                      ...val,
                      blood_type: e.target.value,
                    }))
                  }
                />
              </Col>
              <Col lg={6} className="mb-4">
                <Label for="meslek">Meslek</Label>
                <Input
                  type="text"
                  placeholder="Meslek"
                  id="meslek"
                  className="form-control hesap-ayarlari-input"
                  value={data.job}
                  onChange={(e) =>
                    setData((val) => ({
                      ...val,
                      job: e.target.value.replace(
                        /[^a-zA-ZığüşöçĞÜŞİÖÇ\s]/g,
                        ""
                      ),
                    }))
                  }
                />
              </Col>
              <Col lg={6} className="mb-4">
                <Label for="ulke">Ülke</Label>
                <Input
                  type="text"
                  placeholder="Ülke"
                  id="ulke"
                  className="form-control hesap-ayarlari-input"
                  value={data.country}
                  onChange={(e) =>
                    setData((val) => ({
                      ...val,
                      country: e.target.value.replace(
                        /[^a-zA-ZığüşöçĞÜŞİÖÇ\s]/g,
                        ""
                      ),
                    }))
                  }
                />
              </Col>
              <Col lg={6} className="mb-4">
                <Label for="il">İl</Label>
                <Input
                  type="text"
                  placeholder="İl"
                  id="il"
                  className="form-control hesap-ayarlari-input"
                  value={data.il}
                  onChange={(e) =>
                    setData((val) => ({
                      ...val,
                      il: e.target.value,
                    }))
                  }
                />
              </Col>
              <Col lg={6} className="mb-4">
                <Label for="ilce">İlçe</Label>
                <Input
                  type="text"
                  placeholder="İlçe"
                  id="ilce"
                  className="form-control hesap-ayarlari-input"
                  value={data.ilce}
                  onChange={(e) =>
                    setData((val) => ({
                      ...val,
                      ilce: e.target.value.replace(
                        /[^a-zA-ZığüşöçĞÜŞİÖÇ\s]/g,
                        ""
                      ),
                    }))
                  }
                />
              </Col>
              <Col lg={6} className="mb-4">
                <Label for="detayli">Detay</Label>
                <Input
                  type="text"
                  placeholder="Detay"
                  id="detayli"
                  className="form-control hesap-ayarlari-input"
                  value={data.detayli}
                  onChange={(e) =>
                    setData((val) => ({
                      ...val,
                      detayli: e.target.value,
                    }))
                  }
                />
              </Col>
              <button className="btn btn-dark-green ms-3" type="submit">
                Kaydet
              </button>
            </Row>
          </div>

          <div className="hesap-ozetim-container mt-5">
            <div className="d-flex align-items-center gap-1">
              <p className="m-0 p-0 fs-20 fw-600">Şifre Değiştir</p>
            </div>
            <Row tag={"form"} onSubmit={handlePasswordChange} className="mt-4">
              <Col lg={6} className="mb-4">
                <Label for="eski-sifre">Eski Şifre</Label>
                <Input
                  type="password"
                  placeholder="Eski Şifre"
                  id="eski-sifre"
                  className="form-control hesap-ayarlari-input"
                  onChange={(e) =>
                    setPassword((val) => ({
                      ...val,
                      oldpassword: e.target.value,
                    }))
                  }
                />
              </Col>
              <Col lg={6}></Col>
              <Col lg={6} className="mb-4">
                <Label for="yeni-sifre">Yeni Şifre</Label>
                <Input
                  type="password"
                  placeholder="Yeni Şifre"
                  id="yeni-sifre"
                  className="form-control hesap-ayarlari-input"
                  value={data.password}
                  onChange={(e) =>
                    setPassword((val) => ({
                      ...val,
                      password: e.target.value,
                    }))
                  }
                />
              </Col>
              <Col lg={6} className="mb-4">
                <Label for="yeni-sifre-tekrar">Yeni Şifre (Tekrar)</Label>
                <Input
                  type="password"
                  placeholder="Yeni Şifre (Tekrar)"
                  id="yeni-sifre-tekrar"
                  className="form-control hesap-ayarlari-input"
                  onChange={(e) =>
                    setPassword((val) => ({
                      ...val,
                      passwordconfrim: e.target.value,
                    }))
                  }
                />
              </Col>
              <button className="btn btn-dark-green" type="submit">
                Kaydet
              </button>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ayarlar;
