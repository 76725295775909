import { createSlice } from "@reduxjs/toolkit";

const loginToken = localStorage.getItem("app_login_token")

const initialState = {
    userData: "",
    token:loginToken ? loginToken : "",
    login:loginToken ? true : false
};
export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers:{
        handleLogin: (state,action) => {
            const {jwttoken} = action.payload
            state.token = jwttoken
            state.login = true
            localStorage.setItem("app_login_token",jwttoken)
        },
        handleLoginWithToken: (state,action) => {
            const jwttoken = action.payload
            state.token = jwttoken
            state.login = true
            localStorage.setItem("app_login_token",jwttoken)
        },
        handleLogout : (state) => {
            localStorage.removeItem("app_login_token")
            state.token = ""
            state.login = false
            window.location.href ="/"
        }
    }
  
})
export const { handleLogout,handleLogin,handleLoginWithToken } = userSlice.actions;

export default userSlice.reducer;