import React from "react";
import "../../assets/scss/header.scss";
import "../../assets/scss/color.scss";
import "../../assets/scss/style.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Link } from "react-router-dom";

const Slider = () => {
  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper) => console.log(swiper)}
    >
      <SwiperSlide>
        <div className="position-relative-slider">
          <img
            src="slider2.png"
            alt="main slider"
            className="h-75 w-100 "
          />
          <div className="position-absolute-slider-text">
            <p className="m-0 p-0 text-white montserrat fs-36 fw-700 ">
              Bir Damla Su İle Umuda <br /> Vesile Ol
            </p>
            <p className="m-0 p-0 text-white montserrat fs-24 fw-400 mt-lg-5 mt-2">
              Afrika ve Asya’da hayırseverlerin bağışları <br /> ile açtığı
              binlerce su kuyusu ile kuraklıkla <br /> mücadele eden
              coğrafyalara umut oluyor. 
            </p>
            <div className="d-flex align-items-center gap-3  mt-lg-5 mt-2">
            <Link to={"/detay-bilgi"} className="text-decoration-none outline-white-btn">Detaylı Bilgi</Link>
            <Link to={"/bagislarim"} className="text-decoration-none border-0 white-btn">Bağış Yap</Link>
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default Slider;
