import React from "react";
import Slider from "../../Components/Slider";
import Tabs from "../../Components/Tabs";

const KategoriDetay = () => {
  const data = [
    {
      img: "https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1490000/yemen-aa-1490262.jpg",
      title: "Genel Bağış1",
    },
    {
      img: "https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1490000/yemen-aa-1490262.jpg",
      title: "Genel Bağış2",
    },
    {
      img: "https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1490000/yemen-aa-1490262.jpg",
      title: "Genel Bağış3",
    },
    {
      img: "https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1490000/yemen-aa-1490262.jpg",
      title: "Genel Bağış4",
    },
    {
      img: "https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1490000/yemen-aa-1490262.jpg",
      title: "Genel Bağış5",
    },
    {
      img: "https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1490000/yemen-aa-1490262.jpg",
      title: "Genel Bağış6",
    },
    {
      img: "https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1490000/yemen-aa-1490262.jpg",
      title: "Genel Bağış7",
    },
    {
      img: "https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1490000/yemen-aa-1490262.jpg",
      title: "Genel Bağış8",
    },
    {
      img: "https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1490000/yemen-aa-1490262.jpg",
      title: "Genel Bağış9",
    },
  ];
  return (
    <div>
      <Slider />
      <Tabs />
      <div className="category-content container-fluid mt-lg-0 mt-4">
        <div className="row">
          <div className="col-lg-9 col-12">
            <div className="row">
              {data.map((item, index) => (
                <div key={index} className="col-lg-4 col-md-6 col-12 mb-lg-5 mb-4 w-auto">
                  <div className="help-card">
                    <img
                      src={item.img}
                      className="card-img-top "
                      alt="img"
                      style={{
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                    <div className="card-body p-2">
                      <h5 className="help-title">{item.title}</h5>
                      <div className="row">
                        <div className="col-lg-12 position-relative mb-2">
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="0"
                            />
                            <button className="btn border-0 position-absolute mini-green-btn mini-green-absolute">
                              TL
                            </button>
                          </div>
                        </div>
                      </div>
                      <a
                        href="kategoriler-1.html"
                        className="btn bg-dark-green w-100 text-light-green p-3 mb-2"
                      >
                        Bağış Yap
                      </a>
                      <a
                        href="detayli-bilgi.html"
                        className="btn outline-dark-green w-100 p-3"
                      >
                        Detaylı Bilgi
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-3 col-12">
            <div className="card basket-all w-auto text-center">
              <ul className="list-group list-group-flush d-flex text-center justify-content-center">
                <li
                  className="list-group-item bg-light-green fs-18 fw-500 bagis-sepeti text-center d-flex align-items-center w-100 justify-content-center"
                  style={{
                    bordeEndEndRadius: "0px",
                    borderEndStartRadius: "0px",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                >
                  Bağış Sepetim
                </li>
                <li className="list-group-item p-3">
                  <p className="m-0 fw-600 fs-16 my-2 p-0 ">SEPETİNİZ BOŞ</p>
                  <p className="-0 fw-400 fs-14 lh-md ">
                    Tutarı girip ‘‘Bağış Yap’’ butonuna basarak bağışlarınızı
                    sepete ekleyebilirsiniz.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KategoriDetay;
