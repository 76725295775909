import React, { Fragment, useEffect, useState } from "react";
import {
  Row,
  Col,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap";
import Aside from "./Components/Aside";
import { api } from "../../utils/api";
import { Helmet } from "react-helmet";

const SSS = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const getPageContent = (slug) => {
    api()
      .get(`site-about/sss`)
      .then((res) => {
        setData(res.data.data);
        console.log(res.data.data);
      });
  };
  useEffect(() => {
    getPageContent();
  }, []);

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sıkça Sorulan Sorular | {process.env.REACT_APP_SITE_NAME}</title>
      </Helmet>

      <div className="bg-dark-green-section mb-lg-0 mb-5">
        <p className="m-0 fw-700 fs-32 text-light-green text-center">
          Sıkça Sorulan Sorular
        </p>
      </div>
      <div className="kurumsal-container">
        <Row>
          <Col md="3">
            <Aside />
          </Col>
          <Col md="9">
            <Accordion open={open} toggle={toggle}>
              {data.map((d, key) => (
                <AccordionItem>
                  <AccordionHeader targetId={String(key + 1)}>
                    {d.question}
                  </AccordionHeader>
                  <AccordionBody accordionId={String(key + 1)}>
                    {d.answer}
                  </AccordionBody>
                </AccordionItem>
              ))}
            </Accordion>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default SSS;
