import React from "react";
import "../../assets/scss/header.scss";
import "../../assets/scss/color.scss";
import "../../assets/scss/style.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from 'swiper/modules';

const Project = () => {
  return (
    <div className="gen-container">
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        breakpoints={{
            1024: {
              slidesPerView: 3,
              spaceBetween : 40,
            },
          }}
          modules={[Navigation]}
          navigation={{
            prevEl: '.left-button',
            nextEl: '.next-button',
        }}
    
        className="project  tabs position-relative">
        <div className="row">
          <SwiperSlide className="col-lg-4 col-12">
            <div className="item ">
              <div className="card">
                <img
                  src="https://www.volunteerforever.com/wp-content/uploads/2019/08/rsz_story-time-min.jpg"
                  className="card-img-top"
                  alt="img"
                />
                <div className="card-body p-0 m-0 mt-3">
                  <p className="m-0 title">Kurban Bağışı</p>
                  <p className="card-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                    rutrum magna tortor, et pellentesque velit sagittis ac. Nam
                    gravida placerat purus, sit amet vestibulum est facilisis
                    quis.
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="col-lg-4 col-12">
            <div className="item ">
              <div className="card">
                <img
                  src="https://www.volunteerforever.com/wp-content/uploads/2019/08/rsz_story-time-min.jpg"
                  className="card-img-top"
                  alt="img"
                />
                <div className="card-body p-0 m-0 mt-3">
                  <p className="m-0 title">Kurban Bağışı</p>
                  <p className="card-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                    rutrum magna tortor, et pellentesque velit sagittis ac. Nam
                    gravida placerat purus, sit amet vestibulum est facilisis
                    quis.
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="col-lg-4 col-12">
            <div className="item ">
              <div className="card">
                <img
                  src="https://www.volunteerforever.com/wp-content/uploads/2019/08/rsz_story-time-min.jpg"
                  className="card-img-top"
                  alt="img"
                />
                <div className="card-body p-0 m-0 mt-3">
                  <p className="m-0 title">Kurban Bağışı</p>
                  <p className="card-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                    rutrum magna tortor, et pellentesque velit sagittis ac. Nam
                    gravida placerat purus, sit amet vestibulum est facilisis
                    quis.
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide><SwiperSlide className="col-lg-4 col-12">
            <div className="item ">
              <div className="card">
                <img
                  src="https://www.volunteerforever.com/wp-content/uploads/2019/08/rsz_story-time-min.jpg"
                  className="card-img-top"
                  alt="img"
                />
                <div className="card-body p-0 m-0 mt-3">
                  <p className="m-0 title">Kurban Bağışı</p>
                  <p className="card-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                    rutrum magna tortor, et pellentesque velit sagittis ac. Nam
                    gravida placerat purus, sit amet vestibulum est facilisis
                    quis.
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </div>

        <div className="project-absolute-btn d-lg-flex d-none">
        <button className="btn border-0 bg-grey rounded-circle left-button" id="left-button"><div className=""><svg xmlns="http://www.w3.org/2000/svg" width="43" height="42" viewBox="0 0 43 42" fill="none">
  <path d="M21.5001 0.166687C10.0209 0.166687 0.666748 9.52085 0.666748 21C0.666748 32.4792 10.0209 41.8334 21.5001 41.8334C32.9793 41.8334 42.3334 32.4792 42.3334 21C42.3334 9.52085 32.9793 0.166687 21.5001 0.166687ZM25.2292 27.25C25.8334 27.8542 25.8334 28.8542 25.2292 29.4584C24.9167 29.7709 24.5209 29.9167 24.1251 29.9167C23.7292 29.9167 23.3334 29.7709 23.0209 29.4584L15.6667 22.1042C15.0626 21.5 15.0626 20.5 15.6667 19.8959L23.0209 12.5417C23.6251 11.9375 24.6251 11.9375 25.2292 12.5417C25.8334 13.1459 25.8334 14.1459 25.2292 14.75L18.9792 21L25.2292 27.25Z" fill="#CACACA"/>
</svg></div></button>
        <button className="btn border-0 bg-grey rounded-circle next-button" id="right-button"><div><svg xmlns="http://www.w3.org/2000/svg" width="51" height="50" viewBox="0 0 51 50" fill="none">
  <path d="M25.5001 4.16669C14.0209 4.16669 4.66675 13.5209 4.66675 25C4.66675 36.4792 14.0209 45.8334 25.5001 45.8334C36.9793 45.8334 46.3334 36.4792 46.3334 25C46.3334 13.5209 36.9793 4.16669 25.5001 4.16669ZM31.3126 26.1042L23.9584 33.4584C23.6459 33.7709 23.2501 33.9167 22.8542 33.9167C22.4584 33.9167 22.0626 33.7709 21.7501 33.4584C21.1459 32.8542 21.1459 31.8542 21.7501 31.25L28.0001 25L21.7501 18.75C21.1459 18.1459 21.1459 17.1459 21.7501 16.5417C22.3542 15.9375 23.3542 15.9375 23.9584 16.5417L31.3126 23.8959C31.9376 24.5 31.9376 25.5 31.3126 26.1042Z" fill="#CACACA"/>
</svg></div></button>
      </div>
      </Swiper>
    </div>
  );
};

export default Project;
