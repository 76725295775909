/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment, useEffect, useState } from "react";
import {
  Row,
  Col,
} from "reactstrap";
import Aside from "./Components/Aside";
import { api } from "../../utils/api";
import { Helmet } from "react-helmet";

const BasinOdasi = () => {
  const [data, setData] = useState([]);

  const getPageContent = (slug) => {
    api()
      .get(`site-about/basin-odasi`)
      .then((res) => {
        setData(res.data.data);
      });
  };
  useEffect(() => {
    getPageContent();
  }, []);

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Basın Odası | {process.env.REACT_APP_SITE_NAME}</title>
      </Helmet>
      <div className="bg-dark-green-section mb-lg-0 mb-5">
        <p className="m-0 fw-700 fs-32 text-light-green text-center">
          Basın Odası
        </p>
      </div>
      <div className="kurumsal-container">
        <Row>
          <Col md="3" className="mb-2">
            <Aside />
          </Col>
          <Col md="9">
            {data.map((d) => {
              const images = JSON.parse(d.image);
              return (
                <Row className="mb-5">
                  <Col md="12">
                    <h3>{d.title}</h3>
                  </Col>
                  {images.map((image) => (
                    <Col md="6">
                      <img
                        src={
                          process.env.REACT_APP_BASE_URL +
                          "uploads/" +
                          image
                        }
                        style={{maxWidth:"100%"}}
                        height={350}
                        className="mb-2"
                      />
                    </Col>
                  ))}
                </Row>
              );
            })}
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default BasinOdasi;
