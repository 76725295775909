import { RouterProvider } from "react-router-dom";
import routes from "./router";
import store from "./store";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import { Fragment } from "react";

function App() {
  return (
    <Fragment>
      <Provider store={store}>
        <RouterProvider router={routes} />
      </Provider>
      <Toaster />
    </Fragment>
  );
}

export default App;
