import React from "react";
import Slider from "../Components/Slider";
import Tabs from "../Components/Tabs";
import Project from "../Components/Project";
import NotAlone from "../Components/Section1";
import YardimKampanyasi from "../Components/YardimKampanya";
import Support from "../Components/Support";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Anasayfa | {process.env.REACT_APP_SITE_NAME}</title>
      </Helmet>

      <Slider />
      <Tabs />
      <Project />
      <NotAlone />
      <YardimKampanyasi />
      <Support />
    </div>
  );
};

export default Home;
