import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "../../utils/api";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";

const Projelerimiz = () => {
  const { slug } = useParams();
  const [data, setData] = useState({});
  useEffect(() => {
    api()
      .get(`site-about/projelerimiz/${slug}`)
      .then((res) => {
        setData(res.data.data[0]);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Proje şu anda alınamıyor");
      });
  }, [slug]);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Su Kuyusu | {process.env.REACT_APP_SITE_NAME}</title>
      </Helmet>

      <div className="faaliyet container mb-lg-5 pb-lg-5">
        <p className="m-0 h5 fw-600 fs-20 mt-5">Lorem ipsum dolor sit amet.</p>
        <p className="m-0 fs-16 fw-400 mt-4">
          {data.text0}
          <br />
          <br />
        </p>
        <div className="w-100">
          <img
            src={process.env.REACT_APP_BASE_URL + "/uploads/" + data.image0}
            alt="image"
            className="img-fluid mt-5 arama-image w-100"
          />
        </div>
        <p className="m-0 fs-16 fw-400 mt-4">
          {data.text1}
          <br />
          <br />
        </p>

        <div className="aid-campaigns container ">
          <div className="row justify-content-center mt-5">
            <div className="col-lg-4 col-md-6 col-12 mb-lg-5 mb-4">
              <div className="help-card" style={{ width: "18rem" }}>
                <img
                  src="https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1490000/yemen-aa-1490262.jpg"
                  className="cardscontentimg "
                  alt="img"
                  style={{
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                  }}
                />
                <div className="card-body p-2">
                  <h5 className="help-title">Afrika Su Kuyusu</h5>
                  <div className="row">
                    <div className="col-lg-6 position-relative mb-2">
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="0"
                        />
                        <button className="btn border-0 position-absolute mini-green-btn mini-green-absolute">
                          TL
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-6 position-relative  mb-2">
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="0"
                        />
                        <button className="btn border-0 position-absolute mini-green-btn mini-green-absolute">
                          Adet
                        </button>
                      </div>
                    </div>
                  </div>
                  <a
                    href="kategoriler-1.html"
                    className="btn bg-dark-green w-100 text-light-green p-3 mb-2"
                  >
                    Bağış Yap
                  </a>
                  <a
                    href="detayli-bilgi.html"
                    className="btn outline-dark-green w-100 p-3"
                  >
                    Detaylı Bilgi
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-lg-5 mb-4">
              <div className="help-card" style={{ width: "18rem" }}>
                <img
                  src="https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1490000/yemen-aa-1490262.jpg"
                  className="cardscontentimg "
                  alt="img"
                  style={{
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                  }}
                />
                <div className="card-body p-2">
                  <h5 className="help-title">Afrika Su Kuyusu</h5>
                  <div className="row">
                    <div className="col-lg-6 position-relative mb-2">
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="0"
                        />
                        <button className="btn border-0 position-absolute mini-green-btn mini-green-absolute">
                          TL
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-6 position-relative  mb-2">
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="0"
                        />
                        <button className="btn border-0 position-absolute mini-green-btn mini-green-absolute">
                          Adet
                        </button>
                      </div>
                    </div>
                  </div>
                  <a
                    href="kategoriler-1.html"
                    className="btn bg-dark-green w-100 text-light-green p-3 mb-2"
                  >
                    Bağış Yap
                  </a>
                  <a
                    href="detayli-bilgi.html"
                    className="btn outline-dark-green w-100 p-3"
                  >
                    Detaylı Bilgi
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-lg-5 mb-4">
              <div className="help-card" style={{ width: "18rem" }}>
                <img
                  src="https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1490000/yemen-aa-1490262.jpg"
                  className="cardscontentimg "
                  alt="img"
                  style={{
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                  }}
                />
                <div className="card-body p-2">
                  <h5 className="help-title">Afrika Su Kuyusu</h5>
                  <div className="row">
                    <div className="col-lg-6 position-relative mb-2">
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="0"
                        />
                        <button className="btn border-0 position-absolute mini-green-btn mini-green-absolute">
                          TL
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-6 position-relative  mb-2">
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="0"
                        />
                        <button className="btn border-0 position-absolute mini-green-btn mini-green-absolute">
                          Adet
                        </button>
                      </div>
                    </div>
                  </div>
                  <a
                    href="kategoriler-1.html"
                    className="btn bg-dark-green w-100 text-light-green p-3 mb-2"
                  >
                    Bağış Yap
                  </a>
                  <a
                    href="detayli-bilgi.html"
                    className="btn outline-dark-green w-100 p-3"
                  >
                    Detaylı Bilgi
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-100">
          <img
            src={process.env.REACT_APP_BASE_URL + "/uploads/" + data.image1}
            alt="image"
            className="img-fluid mt-5 arama-image w-100"
          />
        </div>
        <p className="m-0 fs-16 fw-400 mt-4">
          {data.text2}
          <br />
          <br />
        </p>

        <button
          className="btn border-0 bg-dark-green  mx-auto text-light-green mt-4 w-50 py-3 fw-500
            flex-column d-flex align-items-center fs-18 text-nowrap "
        >
          Hemen Bağış Yap
        </button>
      </div>
    </div>
  );
};

export default Projelerimiz;
