import React from 'react'
import { Link } from 'react-router-dom'

const Aside = () => {
  return (
    <div className="d-flex align-items-start justify-content-start flex-column gap-3 p-0 m-0">
    <Link
      to={"/kurumsal/hakkimizda"}
      className="text-decoration-none tabs p-0 m-0"
    >
      <span className="ms-4 p-0 m-0">Hakkımızda</span>
    </Link>
    <Link
      to={"/kurumsal/degerlerimiz"}
      className="text-decoration-none tabs p-0 m-0"
    >
      <span className="ms-4 p-0 m-0">Değerlerimiz</span>
    </Link>
    <Link
      to={"/kurumsal/insan-kaynaklari"}
      className="text-decoration-none tabs p-0 m-0"
    >
      <span className="ms-4 p-0 m-0">İnsan Kaynakları</span>
    </Link>
    <Link
      to={"/kurumsal/basin-odasi"}
      className="text-decoration-none tabs p-0 m-0"
    >
      <span className="ms-4 p-0 m-0">Basın Odası</span>
    </Link>
    <Link
      to={"/kurumsal/yonetim-kurulu"}
      className="text-decoration-none tabs p-0 m-0"
    >
      <span className="ms-4 p-0 m-0">Yönetim Kurulu</span>
    </Link>
    <Link
      to={"/kurumsal/gizlilik"}
      className="text-decoration-none tabs p-0 m-0"
    >
      <span className="ms-4 p-0 m-0">Gizlilik Politikası</span>
    </Link>
    <Link
      to={"/kurumsal/sss"}
      className="text-decoration-none tabs p-0 m-0"
    >
      <span className="ms-4 p-0 m-0">Sıkça Sorulan Sorular</span>
    </Link>
  </div>
  )
}

export default Aside