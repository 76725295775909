import axios from "axios";

const token = localStorage.getItem("app_login_token")


export const api = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      Authorization: `Bearer ${token ? token : ""}`,
    },
  });
};
export const apiInFile = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      Authorization: `Bearer Bearer ${token ? token : ""}`,
      "Content-Type": "multipart/form-data"
    },
  });
};