import React, { useState } from "react";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { Link } from "react-router-dom";
import { IoEyeSharp, IoEyeOffSharp } from "react-icons/io5";
import ShowPassword from "../../Components/ShowPassword";

const SifreYenileme = () => {
  const [activeTab, setActiveTab] = useState("1");

  
  return (
    <div className="login-register">
      <div className="giris-yap-content mx-auto mt-5">
        <div className="row">
          <div className="col-lg-6 col-12 p-0 m-0">
            <img
              src="giris-yap.png"
              alt="contentboximg"
              className="image-box"
            />
          </div>

          <div className="position-relative col-lg-6 col-12 mt-lg-0 mt-5 p-0 m-0 mx-auto d-flex flex-column justify-content-center">
            <div>
              <p
                className="d-lg-block d-none montserrat fs-20 fw-600 text-center p-4 position-text-relative"
                style={{
                  position: "absolute",
                  top: "0",
                  textAlign: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                Şifre Yenileme
              </p>

              <form className="container mt-lg-0">
                <FormGroup className=" px-lg-4">
                  <Label
                    for="tel-no-eposta"
                    className="fw-400 fs-16 montserrat"
                  >
                    Telefon Numarası veya E-posta
                  </Label>
                  <Input
                    id="tel-no-eposta"
                    name="tel-no-eposta"
                    placeholder="Giriniz"
                    type="tel-no-eposta"
                    className="py-3 mt-2"
                  />
                </FormGroup>

                <FormGroup className="px-lg-4 eyes-relative position-relative">
                  <Label for="yeni-sifre" className="fw-400 fs-16 montserrat">
                    Yeni Şifre
                  </Label>
                  <ShowPassword
                    id="yeni-sifre"
                    name="yeni-sifre"
                    placeholder="Giriniz"
                    className=" py-3 mt-2"
                  />
                </FormGroup>

                <FormGroup className="px-lg-4 eyes-relative position-relative">
                  <Label for="yeni-sifre-tekrar" className="fw-400 fs-16 montserrat">
                    Yeni Şifre
                  </Label>
                  <ShowPassword
                    id="yeni-sifre-tekrar"
                    name="yeni-sifre-tekrar"
                    placeholder="Giriniz"
                    className="py-3 mt-2"
                  />
                </FormGroup>

                <div className="px-lg-4">
                  <Button className="btn border-0 dark-green-btn w-100 py-3">
                    Onayla
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SifreYenileme;
