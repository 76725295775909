import React, { useState } from "react";
import { Button, ButtonGroup } from "reactstrap";
const DetayBilgi = () => {
  const [cSelected, setCSelected] = useState([]);
  const [rSelected, setRSelected] = useState(null);

  const onCheckboxBtnClick = (selected) => {
    const index = cSelected.indexOf(selected);
    if (index < 0) {
      cSelected.push(selected);
    } else {
      cSelected.splice(index, 1);
    }
    setCSelected([...cSelected]);
  };
  return (
    <div>
      {/* green bg title */}
      <div className="bg-dark-green-section mb-lg-0 mb-5">
        <p className="m-0 fw-700 fs-32 text-light-green text-center">
          Ramazan Bağışı 2024
        </p>
      </div>
      {/* green bg title */}
      <div className="detayli-bilgi container mt-5">
        <div className="d-flex align-items-center justify-content-lg-between justify-content-start flex-wrap">
          <div className="d-flex gap-5 align-items-center justify-content-lg-between justify-content-start flex-wrap">
            <img
              src="https://www.indyturk.com/sites/default/files/styles/1368x911/public/article/main_image/2023/06/17/1157601-1492814114.jpg?itok=hfVEgZJc"
              className="detay-bilgi-img"
              alt=" contentimgbox"
            />
            <div>
              <p className="m-0 p-0 inter fw-500 fs-20">Dikiş Makinesi</p>
              <ButtonGroup className="detay-btns-content">
                <Button
                  color="primary"
                  outline
                  onClick={() => setRSelected(1)}
                  active={rSelected === 1}
                >
                  100 TL
                </Button>
                <Button
                  color="primary"
                  outline
                  onClick={() => setRSelected(2)}
                  active={rSelected === 2}
                >
                  250 TL
                </Button>
                <Button
                  color="primary"
                  outline
                  onClick={() => setRSelected(3)}
                  active={rSelected === 3}
                >
                  500 TL
                </Button>
                <Button
                  color="primary"
                  outline
                  onClick={() => setRSelected(4)}
                  active={rSelected === 4}
                >
                  Diğer
                </Button>
              </ButtonGroup>
              <div className="position-relative-content mt-4">
                <input className="form-control " value={1.000}/>
                <button className=" btn border-0 absolute-content-boxs">
                  TL
                </button>
              </div>
            </div>
            <div className="btns-dark">
              <button className="btn border-0 dark-btnss">Bağış Yap</button>
            </div>
          </div>

        </div>

        <p className="m-0 h5 fw-600 fs-20 mt-5">Ramazan Bağışı</p>
        <p className="m-0 fs-16 fw-400 mt-4">
          Ramazan, rahmeti ve bereketiyle tüm İslam alemini selamlarken Vuslat,
          her zaman olduğu gibi bu Ramazan ayında da Ramazan’ın bereketi ve
          hayırsever bağışçılarının desteğiyle 4 kıta 20 ülkede ihtiyaç sahibi
          kardeşlerinin yardımına koşuyor. Ramazan; kardeşliğin, paylaşmanın,
          mutluluğun, güzelliğin hasılı tüm iyiliklerin katlanarak yaşanmasına
          vesile olduğu için bu hayırlı yolculuğumuzu “İyiliğe Vesile
          Ramazan” çağrısıyla başlatıyoruz.
          <br />
          <br />
          Ramazan ayını iyiliğe vesile kılarak kumanyalar dağıtıyor, nafile
          kurbanlar kesiyor, su kuyuları açıyor; ihtiyaç sahiplerine zekat,
          fitre, fidye ve sadaka gibi nakdi yardımlar ulaştırıyor; yetimhane ve
          medreselerde ilim talebeleri için iftar sofraları kuruyoruz.
          <br />
          <br />
          Vuslat, 22 yıllık tecrübesiyle bu Ramazan’da da ihtiyaç sahiplerinin
          yardımına koşuyor. Sizler de Ramazan ayında yapacağınız bağışlar ile
          Asya’dan Afrika’ya uzanan iyilik yolculuğumuza destek olabilir,
          ihtiyaç sahiplerinin hayır dualarında yer edinebilirsiniz.
        </p>
        <div>
          <img
            src="https://news.cgtn.com/news/3d3d774e3441544e79457a6333566d54/img/74d4d6394b9445589faa1485cf7066f8/74d4d6394b9445589faa1485cf7066f8.jpg"
            alt="image"
            className="img-fluid mt-5 arama-image"
          />
        </div>

        <p className="m-0 h5 fw-600 fs-20 mt-5">
          Zekatınız Ramazan İle Bereketlensin
        </p>
        <p className="m-0 fs-16 fw-400 mt-4">
          Zekat, dinimiz İslam’ın belirttiği hususlar ışığında zengin sayılan,
          nisap miktarınca (80.18 gram altın) mal yahut paraya sahip her
          Müslümana farzdır. Yılda bir kere ihtiyaç sahiplerine verilmesi farz
          olan zekat için Ramazan ayı büyük fırsattır. Çünkü Ramazan rahmet,
          mağfiret ve bereket ayıdır.
          <br />
          <br />
          Vuslat, Ramazan ayında hayırseverlerden temin ettiği zekat bağışlarını
          yurt içinde ve yurt dışındaki ihtiyaç sahiplerine ulaştırmakta,
          iyiliğe köprü olmaktadır. Sizler de Ramazan ayında zekat bağışı
          yaparak zekatınızı bereketlendirebilir, ihtiyaç sahiplerinin
          dualarında yer edinebilirsiniz.
        </p>
        <p className="m-0 h5 fw-600 fs-20 mt-5">Ramazan Bağışı</p>
        <p className="m-0 fs-16 fw-400 mt-4">
          Ramazan, rahmeti ve bereketiyle tüm İslam alemini selamlarken Vuslat,
          her zaman olduğu gibi bu Ramazan ayında da Ramazan’ın bereketi ve
          hayırsever bağışçılarının desteğiyle 4 kıta 20 ülkede ihtiyaç sahibi
          kardeşlerinin yardımına koşuyor. Ramazan; kardeşliğin, paylaşmanın,
          mutluluğun, güzelliğin hasılı tüm iyiliklerin katlanarak yaşanmasına
          vesile olduğu için bu hayırlı yolculuğumuzu “İyiliğe Vesile
          Ramazan” çağrısıyla başlatıyoruz.
          <br />
          <br />
          Ramazan ayını iyiliğe vesile kılarak kumanyalar dağıtıyor, nafile
          kurbanlar kesiyor, su kuyuları açıyor; ihtiyaç sahiplerine zekat,
          fitre, fidye ve sadaka gibi nakdi yardımlar ulaştırıyor; yetimhane ve
          medreselerde ilim talebeleri için iftar sofraları kuruyoruz.
          <br />
          <br />
          Vuslat, 22 yıllık tecrübesiyle bu Ramazan’da da ihtiyaç sahiplerinin
          yardımına koşuyor. Sizler de Ramazan ayında yapacağınız bağışlar ile
          Asya’dan Afrika’ya uzanan iyilik yolculuğumuza destek olabilir,
          ihtiyaç sahiplerinin hayır dualarında yer edinebilirsiniz.
        </p>
        <div>
          <img
            src="https://news.cgtn.com/news/3d3d774e3441544e79457a6333566d54/img/74d4d6394b9445589faa1485cf7066f8/74d4d6394b9445589faa1485cf7066f8.jpg"
            alt="image"
            className="img-fluid mt-5 arama-image"
          />
        </div>
        <p className="m-0 h5 fw-600 fs-20 mt-5">Ramazan Bağışı</p>
        <p className="m-0 fs-16 fw-400 mt-4">
          Ramazan, rahmeti ve bereketiyle tüm İslam alemini selamlarken Vuslat,
          her zaman olduğu gibi bu Ramazan ayında da Ramazan’ın bereketi ve
          hayırsever bağışçılarının desteğiyle 4 kıta 20 ülkede ihtiyaç sahibi
          kardeşlerinin yardımına koşuyor. Ramazan; kardeşliğin, paylaşmanın,
          mutluluğun, güzelliğin hasılı tüm iyiliklerin katlanarak yaşanmasına
          vesile olduğu için bu hayırlı yolculuğumuzu “İyiliğe Vesile
          Ramazan” çağrısıyla başlatıyoruz.
          <br />
          <br />
          Ramazan ayını iyiliğe vesile kılarak kumanyalar dağıtıyor, nafile
          kurbanlar kesiyor, su kuyuları açıyor; ihtiyaç sahiplerine zekat,
          fitre, fidye ve sadaka gibi nakdi yardımlar ulaştırıyor; yetimhane ve
          medreselerde ilim talebeleri için iftar sofraları kuruyoruz.
          <br />
          <br />
          Vuslat, 22 yıllık tecrübesiyle bu Ramazan’da da ihtiyaç sahiplerinin
          yardımına koşuyor. Sizler de Ramazan ayında yapacağınız bağışlar ile
          Asya’dan Afrika’ya uzanan iyilik yolculuğumuza destek olabilir,
          ihtiyaç sahiplerinin hayır dualarında yer edinebilirsiniz.
        </p>
        <button
          className="btn border-0 bg-dark-green  mx-auto text-light-green mt-5 w-50 py-3 fw-500
flex-column d-flex align-items-center fs-18 text-nowrap"
        >
          Hemen Bağış Yap
        </button>
      </div>
    </div>
  );
};

export default DetayBilgi;
