import React from 'react'

const Faaliyetlerimiz = () => {
  return (
    <div>
       {/* green bg title */}
      <div className="bg-dark-green-section mb-lg-0 mb-5">
        <p className="m-0 fw-700 fs-32 text-light-green text-center">
        Arama Kurtarma
        </p>
      </div>
      {/* green bg title */}
      <div className="faaliyet container">
            <p className="m-0 h5 fw-600 fs-20 mt-5">Arama Kurtarma Çalışmalarımız</p>
            <p className="m-0 fs-16 fw-400 mt-4">Ülkemiz, deprem başta olmak üzere yangın, sel, fırtına gibi çeşitli âfet ve felaketlerle sık sık boğuşmaktadır. Özellikle fay hatlarının yoğun olduğu bir ülke olarak deprem sonucu yıkıntılardan, insan başta olmak üzere her canlıyı kurtarmak için arama kurtarma çalışmaları yapılmaktadır. 
                <br/><br/>
                Derneğimiz ise; âfet ve felaketlerde mağdur olup ölümle burun buruna gelen her canlıya yardım elini uzatıp can suyu olmak için çabalamayı ilke edinmiştir.
                Derneğimizin arama kurtarma çalışmaları; gerekli eğitimleri almış uzman ekipler tarafından yürütülmektedir.
                <br/><br/>
                Arama kurtarma ekiplerimiz; deprem, yangın ve sel gibi felaketler için ayrı ayrı özel eğitime tâbi tutulurlar.
            </p>
            <div>
                <img src="https://news.cgtn.com/news/3d3d774e3441544e79457a6333566d54/img/74d4d6394b9445589faa1485cf7066f8/74d4d6394b9445589faa1485cf7066f8.jpg" alt="boximg" className="img-fluid mt-5 arama-image"/>
            </div>
            <div>
                <p className="m-0 h5 mt-3 fw-600 fs-20 mb-3">Sel</p>
                <p className="m-0 fs-16 fw-400 mt-lg-2">Ülkemiz önemli ve aktif deprem bölgeleri arasındadır. Yaşanan depremlerin etkisiyle göçük altında kalan insanların sayısı da fazla olabilmektedir. Arama kurtarma ekibimiz deprem sonrası göçük altında kalan insanların bulunması ve kurtarılması için gerekli olan eğitimleri alırlar. Bunların en önemlisi telsiz eğitimidir. Telsiz eğitimi alan ekiplerimiz, telsiz üzerinden haberleşmeyi sağlayarak yaralılara ulaşılmasını, haberleşmeyi ve yaralıların doğru şekilde çıkarılmasını hedeflerler.</p>
                <p className="m-0 h5 mt-3 fw-600 fs-20 mb-3">Yangın</p>
                <p className="m-0 fs-16 fw-400 mt-lg-2">Yangın için gerekli eğitimler yangın simülasyonlarından yararlanılarak verilir. Bu sayede ekibe katılan personelimiz; yangın ekipmanlarının kullanımı, yangının ortasında kalan yaralıların kurtarılması ve kurtarma çalışmalarının problemsiz ve en başarılı şekilde gerçekleşmesi için gerekli eğitimi alırlar.</p>
                <p className="m-0 h5 mt-3 fw-600 fs-20 mb-3">Amaç, Hayat Kurtarmak</p>
                <p className="m-0 fs-16 fw-400 mt-lg-2">Âfet ya da felaketin meydana gelmesinin hemen akabinde uzman ekiplerimiz olay mahalline en hızlı bir biçimde ulaşarak gerekli çalışmalara başlarlar. Amaç ise, daha çok insanın hayatını kurtarmaktır. Profesyonel ekiplerimiz bunun için olanca güçleriyle çalışmaktadır.</p>
                <button className="btn bg-dark-green-btn mt-5 mx-auto text-light-green mt-4 w-50 py-3 fw-500
                 flex-column d-flex align-items-center fs-18 text-nowrap " >Hemen Bağış Yap</button>
          </div>

          </div>
    </div>
  )
}

export default Faaliyetlerimiz