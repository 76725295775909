import React, { useEffect, useState } from "react";
import "../assets/scss/style.scss";
import "../assets/scss/color.scss";
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { api } from "../utils/api";
import axios from "axios";
import toast from "react-hot-toast";
// BAĞLAYAMADIMM
const GonulluForm = () => {
  const [values, setValues] = useState({
    namesurname: "",
    birthdate: "",
    gender: "",
    mail: "",
    gsm: "",
    il: "",
    ilce: "",
    education: "",
    educationStatus: "",
    sektor: "",
    schoolName: "",
    language: "",
    purpose: "",
    ilgi: "",
    katki: "",
    istek: "",
    idea: "",
    referans: "",
  });
  
  // VERİLERİN HEPSİNİ AL
  const handleSubmit = (e) => {
    e.preventDefault();
    api().post("contact/gonul", values)
      .then((response) => {
        toast.success("Gönüllülük Talebiniz başarıyla alınmıştır. Ekiplerimiz sizinle iletişime geçecektir")
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Gönüllülük Talebiniz alınırken bir hata ile karşılaşıldı. Ekiplerimiz sizinle iletişime geçecektir")

      });
  };

  return (
    <div>
      {/* green bg title */}
      <div className="bg-dark-green-section mb-lg-0 mb-5">
        <p className="m-0 fw-700 fs-32 text-light-green text-center">
          Gönüllü Başvuru Formu
        </p>
        <span className="text-white text-center d-flex justify-content-center mt-4 fs-14">
          Lütfen aşağıda yer alan formu eksiksiz doldurunuz.
        </span>
      </div>
      {/* green bg title */}
      <div className="gonullu-card container py-3 mt-5">
        <div className="gonullu-form p-3">
          <p className="m-0 p-0">
            Sevgili Gönüllü Adayımız, ilgin için teşekkür ederiz. Gönüllü
            formumuzu doldurabilir ve sonrasında e-posta yoluyla seninle
            iletişime geçeceğiz. Tüm soru ve görüşlerini{" "}
            <b className="text"> stkyazilim.org</b> adresinden bize
            iletebilirsin.
          </p>
            <Row  tag={"form"} onSubmit={handleSubmit} className="d-flex  align-items-end">
              <Col lg={4} xs={12} className="mt-4 px-5 mb-3">
                <label for="ad-soyad" className="form-label">
                  Adınız Soyadınız
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="ad-soyad"
                  name="namesurname"
                  value={values.namesurname}
                  onChange={(e) =>
                    setValues((val) => ({
                      ...val,
                      namesurname: e.target.value,
                    }))
                  }
                />
              </Col>
              <Col lg={4} xs={12} className="mt-4 px-5 mb-3">
                <label for="birthdate" className="form-label">
                  Doğum Günü
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="birthdate"
                  name="birthdate"
                  value={values.birthdate}
                  onChange={(e) =>
                    setValues((val) => ({ ...val, birthdate: e.target.value }))
                  }
                />
              </Col>
              <Col lg={4} xs={12} className="mt-4 px-5 mb-3">
                <label for="gender" className="form-label">
                  Cinsiyet
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="gender"
                  name="gender"
                  value={values.gender}
                  onChange={(e) =>
                    setValues((val) => ({ ...val, gender: e.target.value }))
                  }
                />
              </Col>
              <Col lg={4} xs={12} className="mt-4 px-5 mb-3">
                <label for="mail" className="form-label">
                  Mail Adresi
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="mail"
                  name="mail"
                  value={values.mail}
                  onChange={(e) =>
                    setValues((val) => ({ ...val, mail: e.target.value }))
                  }
                />
              </Col>
              <Col lg={4} xs={12} className="mt-4 px-5 mb-3">
                <label for="gsm" className="form-label">
                  Cep Telefonu
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="gsm"
                  name="gsm"
                  value={values.gsm}
                  onChange={(e) =>
                    setValues((val) => ({ ...val, gsm: e.target.value }))
                  }
                />
              </Col>
              <Col lg={4} xs={12} className="mt-4 px-5 mb-3">
                <label for="il" className="form-label">
                  İl
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="il"
                  name="il"
                  value={values.il}
                  onChange={(e) =>
                    setValues((val) => ({ ...val, il: e.target.value }))
                  }
                />
              </Col>
              <Col lg={4} xs={12} className="mt-4 px-5 mb-3">
                <label for="ilce" className="form-label">
                  İlçe
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="ilce"
                  name="ilce"
                  value={values.ilce}
                  onChange={(e) =>
                    setValues((val) => ({ ...val, ilce: e.target.value }))
                  }
                />
              </Col>
              <Col lg={4} xs={12} className="mt-4 px-5 mb-3">
                <label for="education" className="form-label">
                  Mezuniyet
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="education"
                  name="education"
                  value={values.education}
                  onChange={(e) =>
                    setValues((val) => ({ ...val, education: e.target.value }))
                  }
                />
              </Col>
              <Col lg={4} xs={12} className="mt-4 px-5 mb-3">
                <label for="educationStatus" className="form-label">
                  Eğitim Durumu
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="educationStatus"
                  name="educationStatus"
                  value={values.educationStatus}
                  onChange={(e) =>
                    setValues((val) => ({
                      ...val,
                      educationStatus: e.target.value,
                    }))
                  }
                />
              </Col>
              <Col lg={4} xs={12} className="mt-4 px-5 mb-3">
                <label for="sektor" className="form-label">
                  Sektör
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="sektor"
                  name="sektor"
                  value={values.sektor}
                  onChange={(e) =>
                    setValues((val) => ({ ...val, sektor: e.target.value }))
                  }
                />
              </Col>
              <Col lg={4} xs={12} className="mt-4 px-5 mb-3">
                <label for="schoolType" className="form-label">
                  Okul Tipi
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="schoolType"
                  name="schoolType"
                  value={values.schoolType}
                  onChange={(e) =>
                    setValues((val) => ({ ...val, schoolType: e.target.value }))
                  }
                />
              </Col>
              <Col lg={4} xs={12} className="mt-4 px-5 mb-3">
                <label for="schoolName" className="form-label">
                  Okul Adı
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="schoolName"
                  name="schoolName"
                  value={values.schoolName}
                  onChange={(e) =>
                    setValues((val) => ({ ...val, schoolName: e.target.value }))
                  }
                />
              </Col>
              <Col lg={4} xs={12} className="mt-4 px-5 mb-3">
                <label for="dil" className="form-label">
                  Dil
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="dil"
                  name="dil"
                  value={values.dil}
                  onChange={(e) =>
                    setValues((val) => ({ ...val, dil: e.target.value }))
                  }
                />
              </Col>
              <Col lg={4} xs={12} className="mt-4 px-5 mb-3">
                <label for="ilgi" className="form-label">
                  Hobileriniz
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="ilgi"
                  name="ilgi"
                  value={values.ilgi}
                  onChange={(e) =>
                    setValues((val) => ({ ...val, ilgi: e.target.value }))
                  }
                />
              </Col>
              <Col lg={4} xs={12} className="mt-4 px-5 mb-3">
                <label for="Referans" className="form-label">
                  Referanslar
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="Referans"
                  name="Referans"
                  value={values.Referans}
                  onChange={(e) =>
                    setValues((val) => ({ ...val, Referans: e.target.value }))
                  }
                />
              </Col>
              <Col lg={12} xs={12} className="mt-4 px-5 mb-1">
                <label for="katki" className="form-label">
                  Nasıl katkıda bulunabilirsiniz?
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="katki"
                  name="katki"
                  value={values.katki}
                  onChange={(e) =>
                    setValues((val) => ({ ...val, katki: e.target.value }))
                  }
                />
              </Col>
              <Col lg={12} xs={12} className="mt-4 px-5 mb-1">
                <label for="istek" className="form-label">
                  Genç İHH'da görmek istediğiniz çalışmalar nelerdir?
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="istek"
                  name="istek"
                  value={values.istek}
                  onChange={(e) =>
                    setValues((val) => ({ ...val, istek: e.target.value }))
                  }
                />
              </Col>
              <Col lg={12} xs={12} className="mt-4 px-5 mb-1">
                <label for="purpose" className="form-label">
                  Gönüllü Olma Amacınız
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="purpose"
                  name="purpose"
                  value={values.purpose}
                  onChange={(e) =>
                    setValues((val) => ({ ...val, purpose: e.target.value }))
                  }
                />
              </Col>
              <Col lg={12} xs={12} className="mt-4 px-5 mb-1">
                <label for="idea" className="form-label">
                  Görüş ve Önerileriniz
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="idea"
                  name="idea"
                  value={values.idea}
                  onChange={(e) =>
                    setValues((val) => ({ ...val, idea: e.target.value }))
                  }
                />
              </Col>
              <div className=" d-flex align-items-center justify-content-end px-5">
                <button className="btn border-0 dark-green-btn text-white mt-4 ">
                  Gönder
                </button>
              </div>
            </Row>
        </div>
      </div>
    </div>
  );
};

export default GonulluForm;
