import React from 'react'
import { Link } from 'react-router-dom'

const NotAlone = () => {
  return (
    <div className='container-fluid bg-dark-green'>
      <section className="not-alone bg-dark-green position-relative">
        <div className="left-side">
          <div className="row  px-1">
            <div className="col-lg-6 col-12">
                <span>Yalnız Değilsin</span>
                <p className="title">Lorem ipsum dolor sit amet.</p>
                <p className="m-0 desc">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Harum, porro dicta quod eum voluptate inventore.</p>
                <p className="left-border-desc">Yardım kapmanyalarımızla <span>12,635</span> proje, <span>20</span> Ülke ve <span>8.2</span> milyon inanın <br/> hayatına dokunduk.</p>
                <Link to={"/detay-bilgi"} className="text-decoration-none light-green-btn border-0 btn">Detaylı Bilgi</Link>
              </div>
            <div className="col-lg-6 col-12 d-lg-block d-md-none">
              <img src="section-image.png" alt="help section" className="section-image d-lg-flex d-none"/>
            </div>
          </div>
        </div>
      </section>        
    </div>
  )
}

export default NotAlone