import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "../../utils/api";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";

const HesapNumarasiDetay = () => {
  const { id } = useParams();
  const [data, setData] = useState({bank_title:""});
  useEffect(() => {
    api()
      .get("hesap-numaralari")
      .then((res) => {
        const response = res.data.data;
        const selectedData = response.find((r) => r.id === Number(id));
        selectedData["iban_infos"] = JSON.parse(selectedData["iban_infos"]);
        setData(selectedData);
        console.log(selectedData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleCopyClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Pano başarıyla kopyalandı.");
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.bank_title} Hesap Numaralarımız | {process.env.REACT_APP_SITE_NAME}</title>
      </Helmet>
      {/* green bg title */}
      <div className="bg-dark-green-section mb-lg-0 mb-5">
        <p className="m-0 fw-700 fs-32 text-light-green text-center" title={` ${data.bank_title} Hesap Numaralarımız`}>
        {data.bank_title} Hesap Numaralarımız
        </p>
      </div>
      {/* green bg title */}
      <div className="hesap-container pb-2">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <p className="m-0 p-0 livvic fs-15 fw-500">
              Bağışlarınızın açıklama böşümünde isim, bağış ve telefon numarası
              yazınız. Bağışınızın alındığına dair bilgi mesajı alacaksınız.
            </p>
            <p className="m-0 p-0 livvic fs-15 fw-600">
              SADAKA BAĞIŞI, MERVE KARA - 0 555 222 11 55
            </p>
            <p className="m-0 p-0 livvic fs-15 fw-500">
              Banka hesap numaralarımız;
            </p>
          </div>
        </div>
      </div>
      <div className="hesap-card">
        <div className="d-flex align-items-lg-center justify-content-lg-between flex-wrap">
          <p className="livvic fs-18 fw-600 text-dark">
            {data.bank_title} Hesap Numaralarımız
          </p>
        </div>
        <div className="mt-40 ">
          <p className="m-0 p-0 livvic fs-20 fw-500 mb-1">
            Hesap Adı:
            <span className="livvic fs-20 fw-400">{data.hesap_name}</span>
          </p>
          <p className="m-0 p-0 livvic fs-20 fw-500 mb-1">
            Swift Kodu:{" "}
            <span className="livvic fs-20 fw-400">{data.swift_code}</span>
          </p>
        </div>
        {data?.iban_infos?.map((iban, key) => (
          <div key={key} className="col-lg-12 mb-2 border-bottoms mt-48">
            <h5 className="livvic">{iban.ibanTitle}</h5>
            <div className="row mt-2">
              <div className="col-lg-6 col-12">
                <h5 className="fw-bold livvic">Hesap No</h5>
                <div className="d-flex align-items-center gap-2">
                  <p className="livvic fw-semibold mb-0">{iban.ibanHesapNo}</p>
                  <button
                    className="btn p-0"
                    onClick={() => handleCopyClipboard(iban.ibanHesapNo)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M15.5 13.15H13.33C11.55 13.15 10.1 11.71 10.1 9.92V7.75C10.1 7.34 9.77 7 9.35 7H6.18C3.87 7 2 8.5 2 11.18V17.82C2 20.5 3.87 22 6.18 22H12.07C14.38 22 16.25 20.5 16.25 17.82V13.9C16.25 13.48 15.91 13.15 15.5 13.15Z"
                        fill="#2B4141"
                      />
                      <path
                        d="M17.8198 2H15.8498H14.7598H11.9298C9.66977 2 7.83977 3.44 7.75977 6.01C7.81977 6.01 7.86977 6 7.92977 6H10.7598H11.8498H13.8198C16.1298 6 17.9998 7.5 17.9998 10.18V12.15V14.86V16.83C17.9998 16.89 17.9898 16.94 17.9898 16.99C20.2198 16.92 21.9998 15.44 21.9998 12.83V10.86V8.15V6.18C21.9998 3.5 20.1298 2 17.8198 2Z"
                        fill="#2B4141"
                      />
                      <path
                        d="M11.9806 7.14999C11.6706 6.83999 11.1406 7.04999 11.1406 7.47999V10.1C11.1406 11.2 12.0706 12.1 13.2106 12.1C13.9206 12.11 14.9106 12.11 15.7606 12.11C16.1906 12.11 16.4106 11.61 16.1106 11.31C15.0206 10.22 13.0806 8.26999 11.9806 7.14999Z"
                        fill="#2B4141"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <h5 className="fw-bold livvic">Iban No</h5>
                <div className="d-flex align-items-center gap-2">
                  <p className="livvic fw-semibold mb-0">{iban.ibanNo}</p>
                  <button
                    className="btn p-0"
                    onClick={() => handleCopyClipboard(iban.ibanNo)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M15.5 13.15H13.33C11.55 13.15 10.1 11.71 10.1 9.92V7.75C10.1 7.34 9.77 7 9.35 7H6.18C3.87 7 2 8.5 2 11.18V17.82C2 20.5 3.87 22 6.18 22H12.07C14.38 22 16.25 20.5 16.25 17.82V13.9C16.25 13.48 15.91 13.15 15.5 13.15Z"
                        fill="#2B4141"
                      />
                      <path
                        d="M17.8198 2H15.8498H14.7598H11.9298C9.66977 2 7.83977 3.44 7.75977 6.01C7.81977 6.01 7.86977 6 7.92977 6H10.7598H11.8498H13.8198C16.1298 6 17.9998 7.5 17.9998 10.18V12.15V14.86V16.83C17.9998 16.89 17.9898 16.94 17.9898 16.99C20.2198 16.92 21.9998 15.44 21.9998 12.83V10.86V8.15V6.18C21.9998 3.5 20.1298 2 17.8198 2Z"
                        fill="#2B4141"
                      />
                      <path
                        d="M11.9806 7.14999C11.6706 6.83999 11.1406 7.04999 11.1406 7.47999V10.1C11.1406 11.2 12.0706 12.1 13.2106 12.1C13.9206 12.11 14.9106 12.11 15.7606 12.11C16.1906 12.11 16.4106 11.61 16.1106 11.31C15.0206 10.22 13.0806 8.26999 11.9806 7.14999Z"
                        fill="#2B4141"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HesapNumarasiDetay;
