/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment, useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import Aside from "./Components/Aside";
import { api } from "../../utils/api";
import { Helmet } from "react-helmet";

const Yonetim = () => {
  const [data, setData] = useState([]);

  const getPageContent = () => {
    api()
      .get(`site-about/yonetim-kurulu`)
      .then((res) => {
        setData(res.data.data);
      });
  };
  useEffect(() => {
    getPageContent();
  }, []);

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Yönetim Kurulu | {process.env.REACT_APP_SITE_NAME}</title>
      </Helmet>

      <div className="bg-dark-green-section mb-lg-0 mb-5">
        <p className="m-0 fw-700 fs-32 text-light-green text-center">
          Yönetim Kurulu
        </p>
      </div>
      <div className="kurumsal-container">
        <Row>
          <Col md="3" className="mb-2">
            <Aside />
          </Col>
          <Col md="9">
            {data.map((d) => {
              return (
                <Row className="mb-5 yonetim">
                  <Col md="6">
                    <article dangerouslySetInnerHTML={{__html:d.description}}></article>
                  </Col>
                  <Col md="6">
                    <img
                      src={
                        process.env.REACT_APP_BASE_URL + "uploads/" + d.image
                      }
                    />
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default Yonetim;
