import React, { Fragment, useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { useDispatch, useSelector } from "react-redux";

const PrivateLayout = () => {
  const { pathname } = useLocation();
  const { login } = useSelector((state) => state.user);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [pathname]);

  return (
    <Fragment>
      {login ? (
        <Fragment>
          <Header />
          <Outlet />
          <Footer />
        </Fragment>
      ) : (
        <Fragment>
          <Header />
          <div className="container ">
            <div style={{ height: "300px" }} className="d-flex align-items-center flex-column">
              <h4 className="text-center mt-5 livvic">
                Bu sayfayı görmek için giriş yapınız
              </h4>
              <div className="col-lg-3 col-12">
                <div className="d-flex justify-content-center w-100">
                  <Link
                    to={"/login"}
                    className="btn bg-dark-green text-white btn-lg py-3 fw-bold livvic mt-3 w-100"
                  >
                    {" "}
                    Giriş Yap
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </Fragment>
      )}
    </Fragment>
  );
};

export default PrivateLayout;
