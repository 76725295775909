import React, { useState } from "react";
import { IoEyeOffSharp, IoEyeSharp } from "react-icons/io5";
import { Button, Input } from "reactstrap";

const ShowPassword = ( props ) => {
  const [show, setShow] = useState(false);
  const togglePasswordVisibility = () => {
    setShow(!show);
  };
  return (
    <div className="position-relative">
      <Input type={show ? "text" : "password"} {...props} style={{paddingRight:"30px"}}/>
      
      <Button
        className="eyes-absolute p-0 m-0 btn border-0 bg-transparent d-flex align-items-center justify-content-center"
        style={{
          position: "absolute",
          top: "50%",
          right: "0",
          transform: "translate(-50%,-50%)",
        }}
        onClick={togglePasswordVisibility}
      >
        {show ? (
          <IoEyeOffSharp className="text-dark" size={20} />
        ) : (
          <IoEyeSharp className="text-dark" size={20} />
        )}
      </Button>
    </div>
  );
};

export default ShowPassword;
