import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { api } from "../../../utils/api";

const Aside = () => {
  const [data, setData] = useState({});
  const getUserData = () => {
    api()
      .get("profile-get")
      .then((res) => {
        const newData = res.data.data[0];
        delete newData.password;
        delete newData.jwttoken;
        setData(newData);
        console.log(newData);
      });
  };
  useEffect(() => {
    getUserData();
  }, []);
  const [activeTab, setActiveTab] = useState("");
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  return (
    <div className="ozet d-flex align-items-start justify-content-start flex-column gap-3 p-0 m-0">
      <div className="ozet-user">
        <div className="d-flex align-items-center gap-2">
          <div className="circle">{Object.keys(data).length>0 && data?.namesurname[0]}</div>
          <div className="d-flex flex-column align-items-start">
            <p className="m-0 p-0 user-name">
             {data?.namesurname}
            </p>
            <Link  to={"/profil/hesap"} className="user-edit">
              Hesabınızı Yönetin
            </Link>
          </div>
        </div>
      </div>
     
      <NavLink
        to={"/profil/ozet"}
        className={({ isActive }) =>
          isActive
            ? `tabs-active text-decoration-none tabs p-0 m-0`
            : " text-decoration-none tabs p-0 m-0"
        }
        onClick={() => handleTabClick("/profil/ozet")}
      >
        <span className="ms-4 p-0 m-0">Bağışlarım</span>
      </NavLink>
      <NavLink
        to={"/profil/hesap"}
        className={({ isActive }) =>
          isActive
            ? `tabs-active text-decoration-none tabs p-0 m-0`
            : " text-decoration-none tabs p-0 m-0"
        }
        onClick={() => handleTabClick("/profil/hesap")}
      >
        <span className="ms-4 p-0 m-0">Hesap Ayarları</span>
      </NavLink>
    </div>
  );
};

export default Aside;
