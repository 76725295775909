import React from "react";
import { Link } from "react-router-dom";

const Bagislarim = () => {
  const data = [
    {
      img: "https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1490000/yemen-aa-1490262.jpg",
      title: "Zekat",
      price: "1.000 TL",
      amount: "x 1 Adet",
    },
    {
      img: "https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1490000/yemen-aa-1490262.jpg",
      title: "Zekat 2",
      price: "2.000 TL",
      amount: "x 2 Adet",
    },
    {
      img: "https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1490000/yemen-aa-1490262.jpg",
      title: "Zekat 3",
      price: "3.000 TL",
      amount: "x 3 Adet",
    },
  ];
  return (
    <div>
      {/* green bg title */}
      <div className="bg-dark-green-section mb-lg-0 mb-5">
        <p className="m-0 fw-700 fs-32 text-light-green text-center">
          Bağışlarım
        </p>
      </div>
      {/* green bg title */}

      <div className="bagislar container ">
        <div className="row justify-content-center">
          {data.map((item,index) => (
            <div key={index} className=" col-lg-4 col-md-6 col-12 ">
              <div className="help-card" style={{ width: "18rem" }}>
                <img
                  src={item.img}
                  className="card-img-top "
                  alt="boxcontent"
                  style={{
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                  }}
                />
                <div className="card-body p-2 px-3">
                  <h5 className="help-title fs-18 fw-500">{item.title}</h5>
                  <div className="d-flex align-items-center justify-content-between my-4">
                    <p className="m-0 fs-20 fw-500 ">{item.price}</p>
                    <p className="m-0 fs-20 fw-500">{item.amount}</p>
                  </div>
                  <Link
                    to={""}
                    className="btn bg-dark-green w-100 text-light-green mb-2 fw-500"
                  >
                    Bağış Yapıldı
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Bagislarim;
