import React from "react";

const YardimKampanyasi = () => {
    const data = [
        {
          imgUrl: "https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1490000/yemen-aa-1490262.jpg",
          title: "Afrika Su Kuyusu"
        },
        {
          imgUrl: "https://indigodergisi.com/wp-content/uploads/2015/12/afrika_cocuk_egitim_gelisim_africa_education_4.jpg",
          title: "Afrika Ekmek Yardımı"
        },
        {
          imgUrl: "https://www.indyturk.com/sites/default/files/article/main_image/2019/06/06/107456-1199094483.jpg",
          title: "Afrika Destek"
        }
      ];
  return (
    <div>
      <div className="aid-campaigns container">
        <p className="m-0 campa-title text-center mb-5 fs-32 fw-600 ">
          Yardım Kampanyalarımız
        </p>
        <div className="row justify-content-center">
        {data.map((item, index) => (
        <div key={index} className="col-lg-4 col-md-6 col-12 mb-lg-5 mb-4">
          <div className="help-card" style={{width: "18rem"}}>
            <img
              src={item.imgUrl}
              className="card-img-top"
              alt="img"
              style={{borderTopLeftRadius: "10px" ,borderTopRightRadius: "10px", width:"100%"}}
            />
            <div className="card-body p-2">
              <h5 className="help-title">{item.title}</h5>
              <div className="row">
                <div className="col-lg-6 position-relative mb-2">
                  <div className="position-relative">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="0"
                    />
                    <button className="btn border-0 position-absolute mini-green-btn mini-green-absolute">
                      TL
                    </button>
                  </div>
                </div>
                <div className="col-lg-6 position-relative mb-2">
                  <div className="position-relative">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="0"
                    />
                    <button className="btn border-0 position-absolute mini-green-btn mini-green-absolute">
                      Adet
                    </button>
                  </div>
                </div>
              </div>
              <a
                href="kategoriler-1.html"
                className="btn bg-dark-green w-100 text-light-green mb-2 p-3"
              >
                Bağış Yap
              </a>
              <a
                href="detayli-bilgi.html"
                className="btn outline-dark-green w-100 p-3"
              >
                Detaylı Bilgi
              </a>
            </div>
          </div>
        </div>
      ))}


        </div>
      </div>
    </div>
  );
};

export default YardimKampanyasi;
