import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { Alert, Col, Row } from "reactstrap";
import Form from "react-bootstrap/Form";
import { HiTrash } from "react-icons/hi2";
const ZekatFormu = () => {
  const [activeTab, setActiveTab] = useState("turklirasi");

  const handleSelect = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="zekat-form py-3 mt-5 mb-5">
      <Row className="coll pb-5">
        <Col lg={6}>
          <Tab.Container activeKey={activeTab} onSelect={handleSelect}>
            <div className="row">
              <Nav
                variant="underline"
                className="flex-column col-2 mt-3"
                defaultActiveKey="/turklirasi"
              >
                <Nav.Item>
                  <Nav.Link className="text-nowrap" eventKey="turklirasi">
                    Türk Lirası
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="text-nowrap" eventKey="doviz">
                    Döviz
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="text-nowrap" eventKey="altin">
                    Altın
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="text-nowrap" eventKey="gumus">
                    Gümüş
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="text-nowrap" eventKey="ticarimallar">
                    Ticari Mallar
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="text-nowrap" eventKey="diger">
                    Diğer
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="text-nowrap" eventKey="alacaklar">
                    Alacaklar
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="text-nowrap" eventKey="borclar">
                    Borçlar
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className="mt-4 col-10">
                <Tab.Pane eventKey="turklirasi">
                  <div className="d-flex flex-column gap-4">
                    <Alert className="m-0 p-0 px-4 py-3 fw-500 alert-success">
                      Asli ihtiyaçlarınız ve gelecek bir yıl içerisinde vadesi
                      dolacak borçlarınız dışında depolarınızda veya
                      raflarınızda bulunan mamul veya yarı mamul ticari
                      mallarınızı Türk lirası veya döviz cinsinden olmak üzere
                      ayrı ayrı sepete ekleyiniz.
                    </Alert>
                    <div className="card p-4">
                      <div>
                        <label for="malinizin-adi" className="mb-2">
                          Malınızın Adı
                        </label>
                        <input
                          className="form-control"
                          placeholder="Malınızın Adı"
                          id="malinizin-adi"
                        />
                      </div>

                      <div>
                        <label className="turu mt-3 mb-2" for="turu">
                          Türü
                        </label>
                        <Form.Select
                          aria-label="Default select example"
                          id="turu"
                        >
                          <option>Seçiniz</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                      </div>

                      <div>
                        <label for="tutar" className="form-label mt-3 mb-2">
                          Tutarı
                        </label>
                        <input
                          className="form-control"
                          id="tutar"
                          value={"0,00"}
                        />
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="doviz">
                  <div className="d-flex flex-column gap-4">
                    <Alert className="m-0 p-0 px-4 py-3 fw-500 alert-success">
                      Asli ihtiyaçlarınız ve gelecek bir yıl içerisinde vadesi
                      dolacak borçlarınız dışında depolarınızda veya
                      raflarınızda bulunan mamul veya yarı mamul ticari
                      mallarınızı Türk lirası veya döviz cinsinden olmak üzere
                      ayrı ayrı sepete ekleyiniz.
                    </Alert>
                    <div className="card p-4">
                      <div>
                        <label for="malinizin-adi" className="mb-2">
                          Malınızın Adı
                        </label>
                        <input
                          className="form-control"
                          placeholder="Malınızın Adı"
                          id="malinizin-adi"
                        />
                      </div>

                      <div>
                        <label className="turu mt-3 mb-2" for="turu">
                          Türü
                        </label>
                        <Form.Select
                          aria-label="Default select example"
                          id="turu"
                        >
                          <option>Seçiniz</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                      </div>

                      <div>
                        <label for="tutar" className="form-label mt-3 mb-2">
                          Tutarı
                        </label>
                        <input
                          className="form-control"
                          id="tutar"
                          value={"0,00"}
                        />
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="altin">
                  <div className="d-flex flex-column gap-4">
                    <Alert className="m-0 p-0 px-4 py-3 fw-500 alert-success">
                      Asli ihtiyaçlarınız ve gelecek bir yıl içerisinde vadesi
                      dolacak borçlarınız dışında depolarınızda veya
                      raflarınızda bulunan mamul veya yarı mamul ticari
                      mallarınızı Türk lirası veya döviz cinsinden olmak üzere
                      ayrı ayrı sepete ekleyiniz.
                    </Alert>
                    <div className="card p-4">
                      <div>
                        <label for="malinizin-adi" className="mb-2">
                          Malınızın Adı
                        </label>
                        <input
                          className="form-control"
                          placeholder="Malınızın Adı"
                          id="malinizin-adi"
                        />
                      </div>

                      <div>
                        <label className="turu mt-3 mb-2" for="turu">
                          Türü
                        </label>
                        <Form.Select
                          aria-label="Default select example"
                          id="turu"
                        >
                          <option>Seçiniz</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                      </div>

                      <div>
                        <label for="tutar" className="form-label mt-3 mb-2">
                          Tutarı
                        </label>
                        <input
                          className="form-control"
                          id="tutar"
                          value={"0,00"}
                        />
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="gumus">
                  <div className="d-flex flex-column gap-4">
                    <Alert className="m-0 p-0 px-4 py-3 fw-500 alert-success">
                      Asli ihtiyaçlarınız ve gelecek bir yıl içerisinde vadesi
                      dolacak borçlarınız dışında depolarınızda veya
                      raflarınızda bulunan mamul veya yarı mamul ticari
                      mallarınızı Türk lirası veya döviz cinsinden olmak üzere
                      ayrı ayrı sepete ekleyiniz.
                    </Alert>
                    <div className="card p-4">
                      <div>
                        <label for="malinizin-adi" className="mb-2">
                          Malınızın Adı
                        </label>
                        <input
                          className="form-control"
                          placeholder="Malınızın Adı"
                          id="malinizin-adi"
                        />
                      </div>

                      <div>
                        <label className="turu mt-3 mb-2" for="turu">
                          Türü
                        </label>
                        <Form.Select
                          aria-label="Default select example"
                          id="turu"
                        >
                          <option>Seçiniz</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                      </div>

                      <div>
                        <label for="tutar" className="form-label mt-3 mb-2">
                          Tutarı
                        </label>
                        <input
                          className="form-control"
                          id="tutar"
                          value={"0,00"}
                        />
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="ticarimallar">
                  <div className="d-flex flex-column gap-4">
                    <Alert className="m-0 p-0 px-4 py-3 fw-500 alert-success">
                      Asli ihtiyaçlarınız ve gelecek bir yıl içerisinde vadesi
                      dolacak borçlarınız dışında depolarınızda veya
                      raflarınızda bulunan mamul veya yarı mamul ticari
                      mallarınızı Türk lirası veya döviz cinsinden olmak üzere
                      ayrı ayrı sepete ekleyiniz.
                    </Alert>
                    <div className="card p-4">
                      <div>
                        <label for="malinizin-adi" className="mb-2">
                          Malınızın Adı
                        </label>
                        <input
                          className="form-control"
                          placeholder="Malınızın Adı"
                          id="malinizin-adi"
                        />
                      </div>

                      <div>
                        <label className="turu mt-3 mb-2" for="turu">
                          Türü
                        </label>
                        <Form.Select
                          aria-label="Default select example"
                          id="turu"
                        >
                          <option>Seçiniz</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                      </div>

                      <div>
                        <label for="tutar" className="form-label mt-3 mb-2">
                          Tutarı
                        </label>
                        <input
                          className="form-control"
                          id="tutar"
                          value={"0,00"}
                        />
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="diger">
                  <div className="d-flex flex-column gap-4">
                    <Alert className="m-0 p-0 px-4 py-3 fw-500 alert-success">
                      Asli ihtiyaçlarınız ve gelecek bir yıl içerisinde vadesi
                      dolacak borçlarınız dışında depolarınızda veya
                      raflarınızda bulunan mamul veya yarı mamul ticari
                      mallarınızı Türk lirası veya döviz cinsinden olmak üzere
                      ayrı ayrı sepete ekleyiniz.
                    </Alert>
                    <div className="card p-4">
                      <div>
                        <label for="malinizin-adi" className="mb-2">
                          Malınızın Adı
                        </label>
                        <input
                          className="form-control"
                          placeholder="Malınızın Adı"
                          id="malinizin-adi"
                        />
                      </div>

                      <div>
                        <label className="turu mt-3 mb-2" for="turu">
                          Türü
                        </label>
                        <Form.Select
                          aria-label="Default select example"
                          id="turu"
                        >
                          <option>Seçiniz</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                      </div>

                      <div>
                        <label for="tutar" className="form-label mt-3 mb-2">
                          Tutarı
                        </label>
                        <input
                          className="form-control"
                          id="tutar"
                          value={"0,00"}
                        />
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="alacaklar">
                  <div className="d-flex flex-column gap-4">
                    <Alert className="m-0 p-0 px-4 py-3 fw-500 alert-success">
                      Asli ihtiyaçlarınız ve gelecek bir yıl içerisinde vadesi
                      dolacak borçlarınız dışında depolarınızda veya
                      raflarınızda bulunan mamul veya yarı mamul ticari
                      mallarınızı Türk lirası veya döviz cinsinden olmak üzere
                      ayrı ayrı sepete ekleyiniz.
                    </Alert>
                    <div className="card p-4">
                      <div>
                        <label for="malinizin-adi" className="mb-2">
                          Malınızın Adı
                        </label>
                        <input
                          className="form-control"
                          placeholder="Malınızın Adı"
                          id="malinizin-adi"
                        />
                      </div>

                      <div>
                        <label className="turu mt-3 mb-2" for="turu">
                          Türü
                        </label>
                        <Form.Select
                          aria-label="Default select example"
                          id="turu"
                        >
                          <option>Seçiniz</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                      </div>

                      <div>
                        <label for="tutar" className="form-label mt-3 mb-2">
                          Tutarı
                        </label>
                        <input
                          className="form-control"
                          id="tutar"
                          value={"0,00"}
                        />
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="borclar">
                  <div className="d-flex flex-column gap-4">
                    <Alert className="m-0 p-0 px-4 py-3 fw-500 alert-success">
                      Asli ihtiyaçlarınız ve gelecek bir yıl içerisinde vadesi
                      dolacak borçlarınız dışında depolarınızda veya
                      raflarınızda bulunan mamul veya yarı mamul ticari
                      mallarınızı Türk lirası veya döviz cinsinden olmak üzere
                      ayrı ayrı sepete ekleyiniz.
                    </Alert>
                    <div className="card p-4">
                      <div>
                        <label for="malinizin-adi" className="mb-2">
                          Malınızın Adı
                        </label>
                        <input
                          className="form-control"
                          placeholder="Malınızın Adı"
                          id="malinizin-adi"
                        />
                      </div>

                      <div>
                        <label className="turu mt-3 mb-2" for="turu">
                          Türü
                        </label>
                        <Form.Select
                          aria-label="Default select example"
                          id="turu"
                        >
                          <option>Seçiniz</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                      </div>

                      <div>
                        <label for="tutar" className="form-label mt-3 mb-2">
                          Tutarı
                        </label>
                        <input
                          className="form-control"
                          id="tutar"
                          value={"0,00"}
                        />
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
        </Col>
        <Col lg={6} className="px-4 mt-3">
          <div className="d-flex justify-content-between align-items-center">
            <p className="m-0 p-0 h5">Hesaplanan Mallarınız</p>
            <div className="d-flex align-items-center gap-2">
              <button className="btn border-0 btn-success text-white p-1 rounded px-3 py-2">
                Döküm Al
              </button>
              <button className="btn border-0 btn-danger text-white p-1 rounded px-3 py-2">
                Tümünü Sil
              </button>
            </div>
          </div>

          <div className="d-flex flex-column">
            <div className="d-flex align-items-center justify-content-between mt-5 mb-1">
              <p className="m-0 p-0 text-success fw-500">
                Altın-14 Ayar Gram Altın
              </p>
              <div className="d-flex align-items-center gap-3">
                <div className="d-flex flex-column align-items-end">
                  <p className="m-0 p-0 mb-1 fw-bolder text-success">20,00</p>
                  <p className="m-0 p-0">Toplam Tutar: 29.633,86 </p>
                </div>
                <div>
                  <button className="btn border-0 text-danger">
                    <HiTrash size={20} />
                  </button>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-5 mb-1">
              <p className="m-0 p-0 text-success fw-500">
                Altın-14 Ayar Gram Altın
              </p>
              <div className="d-flex align-items-center gap-3">
                <div className="d-flex flex-column align-items-end">
                  <p className="m-0 p-0 mb-1 fw-bolder text-success">20,00</p>
                  <p className="m-0 p-0">Toplam Tutar: 29.633,86 </p>
                </div>
                <div>
                  <button className="btn border-0 text-danger">
                    <HiTrash size={20} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ZekatFormu;
