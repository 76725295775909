import React from "react";
import Aside from "./components/Aside";
import "../../assets/scss/style.scss";
import "../../assets/scss/color.scss";
import { Col, Row } from "reactstrap";
const Ozet = () => {
  return (
    <div className="p-0 m-0 overflow-hidden">
      <div className="row" style={{ padding: "50px 80px" }}>
        <div className="col-lg-3">
          <Aside />
        </div>
        <div className="col-lg-9">
          <div className="hesap-ozetim-container">
            <div className="d-flex align-items-center gap-1">
              <p className="m-0 p-0 fs-20 fw-600">Hesap Özetim</p>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM15.5303 9.96967C15.8232 10.2626 15.8232 10.7374 15.5303 11.0303L12.5303 14.0303C12.3897 14.171 12.1989 14.25 12 14.25C11.8011 14.25 11.6103 14.171 11.4697 14.0303L8.46967 11.0303C8.17678 10.7374 8.17678 10.2626 8.46967 9.96967C8.76256 9.67678 9.23744 9.67678 9.53033 9.96967L12 12.4393L14.4697 9.96967C14.7626 9.67678 15.2374 9.67678 15.5303 9.96967Z"
                    fill="#1C274C"
                  />
                </svg>
              </span>
            </div>
            <Row className="mt-4">
              <Col lg={3} className="mb-4">
                <div className="d-flex flex-column">
                  <p className="m-0 p-0 fs-16 fw-400">Bireysel Bağış</p>
                  <div className="bagis-card mt-3">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <p className="m-0 p-0 fs-18 fw-400">Bireysel Bağış</p>
                      <p className="m-0 p-0 fs-20 fw-500 mt-2">0,0 TL</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={3} className="mb-4">
                <div className="d-flex flex-column">
                  <p className="m-0 p-0 fs-16 fw-400">Genel Yardım</p>
                  <div className="bagis-card mt-3">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <p className="m-0 p-0 fs-18 fw-400">Genel Yardım</p>
                      <p className="m-0 p-0 fs-20 fw-500 mt-2">0,0 TL</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={3} className="mb-4">
                <div className="d-flex flex-column">
                  <p className="m-0 p-0 fs-16 fw-400">Gazze Bağışı</p>
                  <div className="bagis-card mt-3">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <p className="m-0 p-0 fs-18 fw-400">Gazze Bağışı</p>
                      <p className="m-0 p-0 fs-20 fw-500 mt-2">0,0 TL</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={3} className="mb-4">
                <div className="d-flex flex-column">
                  <p className="m-0 p-0 fs-16 fw-400">Kurban Bağışı</p>
                  <div className="bagis-card mt-3">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <p className="m-0 p-0 fs-18 fw-400">Kurban Bağışı</p>
                      <p className="m-0 p-0 fs-20 fw-500 mt-2">0,0 TL</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={3} className="mb-4">
                <div className="d-flex flex-column">
                  <p className="m-0 p-0 fs-16 fw-400">Yetim Maaşı</p>
                  <div className="bagis-card mt-3">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <p className="m-0 p-0 fs-18 fw-400">Yetim Maaşı</p>
                      <p className="m-0 p-0 fs-20 fw-500 mt-2">0,0 TL</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={3} className="mb-4">
                <div className="d-flex flex-column">
                  <p className="m-0 p-0 fs-16 fw-400">Bereket Paketi</p>
                  <div className="bagis-card mt-3">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <p className="m-0 p-0 fs-18 fw-400">Bereket Paketi</p>
                      <p className="m-0 p-0 fs-20 fw-500 mt-2">0,0 TL</p>
                    </div>
                  </div>
                </div>
              </Col>    
              <Col lg={3} className="mb-4">
                <div className="d-flex flex-column">
                  <p className="m-0 p-0 fs-16 fw-400">Minik Dostlarımız</p>
                  <div className="bagis-card mt-3">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <p className="m-0 p-0 fs-18 fw-400">Minik Dostlarımız</p>
                      <p className="m-0 p-0 fs-20 fw-500 mt-2">0,0 TL</p>
                    </div>
                  </div>
                </div>
              </Col>             
              <Col lg={3} className="mb-4">
                <div className="d-flex flex-column">
                  <p className="m-0 p-0 fs-16 fw-400">Giyim Paleti</p>
                  <div className="bagis-card mt-3">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <p className="m-0 p-0 fs-18 fw-400">Giyim Paleti</p>
                      <p className="m-0 p-0 fs-20 fw-500 mt-2">0,0 TL</p>
                    </div>
                  </div>
                </div>
              </Col>   
              <Col lg={3} className="mb-4">
                <div className="d-flex flex-column">
                  <p className="m-0 p-0 fs-16 fw-400">Su Kuyusu</p>
                  <div className="bagis-card mt-3">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <p className="m-0 p-0 fs-18 fw-400">Su Kuyusu</p>
                      <p className="m-0 p-0 fs-20 fw-500 mt-2">0,0 TL</p>
                    </div>
                  </div>
                </div>
              </Col>    
              <Col lg={3} className="mb-4">
                <div className="d-flex flex-column">
                  <p className="m-0 p-0 fs-16 fw-400">Ramazan Bağışı</p>
                  <div className="bagis-card mt-3">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <p className="m-0 p-0 fs-18 fw-400">Ramazan Bağışı</p>
                      <p className="m-0 p-0 fs-20 fw-500 mt-2">0,0 TL</p>
                    </div>
                  </div>
                </div>
              </Col>  
            </Row>
              <button className="btn btn-dark-green">Bağış Yap</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ozet;
