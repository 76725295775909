import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { api } from "../../utils/api";
import { Helmet } from "react-helmet";

const HesapNumarasi = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    api()
      .get("hesap-numaralari")
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Hesap Numaralarımız | {process.env.REACT_APP_SITE_NAME}</title>
      </Helmet>

      {/* green bg title */}
      <div className="bg-dark-green-section mb-lg-0 mb-5">
        <p className="m-0 fw-700 fs-32 text-light-green text-center">
          Hesap Numaralarımız
        </p>
      </div>
      {/* green bg title */}

      <div className="hesap-container">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <p className="m-0 p-0 livvic fs-15 fw-500">
              Bağışlarınızın açıklama böşümünde isim, bağış ve telefon numarası
              yazınız. Bağışınızın alındığına dair bilgi mesajı alacaksınız.
            </p>
            <p className="m-0 p-0 livvic fs-15 fw-600">
              SADAKA BAĞIŞI, MERVE KARA - 0 555 222 11 55
            </p>
            <p className="m-0 p-0 livvic fs-15 fw-500">
              Banka hesap numaralarımız;
            </p>
          </div>
          {data.map((item, key) => (
            <div className="col-lg-12 my-2" key={key}>
              <Link
                to={`/hesap-numarasi-detay/${item.id}`}
                className="bank-card text-decoration-none text-dark d-flex align-items-center flex-wrap justify-content-lg-between justify-content-center"
              >
                <p className="m-0 p-0 livvic">{item.bank_title}</p>
                <p className="m-0 p-0 livvic">
                  {item.bank_title} Hesap Bilgileri
                </p>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HesapNumarasi;
