import React, { Fragment, useEffect, useState } from "react";
import "../../assets/scss/header.scss";
import "../../assets/scss/color.scss";
import "../../assets/scss/style.scss";
import { HiMiniBars3BottomRight } from "react-icons/hi2";

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleLogout } from "../../store/user";
import { api } from "../../utils/api";

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [open, setOpen] = useState("1");
  const [projects, setProjects] = useState([]);
  const { login } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const toggles = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const getProjects = () => {
    api()
      .get("site-about/projelerimiz-all")
      .then((res) => {
        setProjects(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getProjects();
  }, []);
  return (
    <div>
      <nav className="bg-blue d-flex justify-content-end align-items-center gap-2">
        <i className="fa-solid fa-sort-down"></i>
        <Link
          to={"/hesap-numarasi"}
          className="text-white montserrat fs-14 fw-500 py-3 "
        >
          Hesap Numaralarımız
        </Link>
        <Dropdown isOpen={dropdownOpen} toggle={toggle} className="me-4">
          <DropdownToggle className="bg-transparent border-0 me-1 fs-14 fw-400 montserrat me-5">
            TR{" "}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>Dil Seçimi</DropdownItem>
            <DropdownItem>TR</DropdownItem>
            <DropdownItem>ENG</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </nav>

      <div className="gen-container">
        <header className="d-lg-flex d-none align-items-center  justify-content-between flex-wrap">
          <div className="d-flex align-items-center gap-5 p-0 m-0">
            <Link
              href="home"
              className="text-decoration-none text-dark p-0 m-0"
            >
              <img src="/STKKK.png" width={130} />
            </Link>

            <div className="d-flex align-items-center gap-5 p-0 m-0">
              <div className="dropdown">
                <button className="dropbtn d-flex align-items-center gap-2">
                  <p className="m-0 p-0">Kurumsal</p>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM15.5303 9.96967C15.8232 10.2626 15.8232 10.7374 15.5303 11.0303L12.5303 14.0303C12.3897 14.171 12.1989 14.25 12 14.25C11.8011 14.25 11.6103 14.171 11.4697 14.0303L8.46967 11.0303C8.17678 10.7374 8.17678 10.2626 8.46967 9.96967C8.76256 9.67678 9.23744 9.67678 9.53033 9.96967L12 12.4393L14.4697 9.96967C14.7626 9.67678 15.2374 9.67678 15.5303 9.96967Z"
                        fill="#1C274C"
                      />
                    </svg>
                  </span>
                </button>
                <div className="dropdown-content">
                  <Link to={"/kurumsal/hakkimizda"}>Hakkımızda</Link>
                  <Link to={"/kurumsal/degerlerimiz"}>Değerlerimiz</Link>
                  <Link to={"/kurumsal/insan-kaynaklari"}>
                    İnsan Kaynakları
                  </Link>
                  <Link to={"/kurumsal/basin-odasi"}>Basın Odası</Link>
                  <Link to={"/kurumsal/yonetim-kurulu"}>Yönetim Kurulu</Link>
                  <Link to={"/kurumsal/sss"}>Sıkça Sorulan Sorular</Link>
                </div>
              </div>
              <div className="dropdown">
                <button className="dropbtn d-flex align-items-center gap-2">
                  <p className="m-0 p-0">Faaliyetlerimiz</p>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM15.5303 9.96967C15.8232 10.2626 15.8232 10.7374 15.5303 11.0303L12.5303 14.0303C12.3897 14.171 12.1989 14.25 12 14.25C11.8011 14.25 11.6103 14.171 11.4697 14.0303L8.46967 11.0303C8.17678 10.7374 8.17678 10.2626 8.46967 9.96967C8.76256 9.67678 9.23744 9.67678 9.53033 9.96967L12 12.4393L14.4697 9.96967C14.7626 9.67678 15.2374 9.67678 15.5303 9.96967Z"
                        fill="#1C274C"
                      />
                    </svg>
                  </span>
                </button>
                <div className="dropdown-content">
                  <Link to={"/faaliyetlerimiz"}>Arama Kurtarma</Link>
                </div>
              </div>
              <div className="dropdown">
                <button className="dropbtn d-flex align-items-center gap-2">
                  <p className="m-0 p-0">Projelerimiz</p>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM15.5303 9.96967C15.8232 10.2626 15.8232 10.7374 15.5303 11.0303L12.5303 14.0303C12.3897 14.171 12.1989 14.25 12 14.25C11.8011 14.25 11.6103 14.171 11.4697 14.0303L8.46967 11.0303C8.17678 10.7374 8.17678 10.2626 8.46967 9.96967C8.76256 9.67678 9.23744 9.67678 9.53033 9.96967L12 12.4393L14.4697 9.96967C14.7626 9.67678 15.2374 9.67678 15.5303 9.96967Z"
                        fill="#1C274C"
                      />
                    </svg>
                  </span>
                </button>
                <div className="dropdown-content">
                  {projects.map((project, key) => (
                    <Link to={`/projelerimiz/${project.slug}`}>
                      {project.text1}
                    </Link>
                  ))}
                </div>
              </div>
              <Link
                to={"iletisim"}
                className="text-decoration-none fw-400 fs-16 montserrat text-blue"
              >
                İletişim
              </Link>
            </div>
          </div>

          <div className="d-flex gap-4 align-items-center ">
            <div className="dropdown">
              <button className="dropbtn d-flex align-items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <circle cx="12" cy="6" r="4" fill="#1C274C" />
                  <path
                    d="M20 17.5C20 19.9853 20 22 12 22C4 22 4 19.9853 4 17.5C4 15.0147 7.58172 13 12 13C16.4183 13 20 15.0147 20 17.5Z"
                    fill="#1C274C"
                  />
                </svg>
              </button>
              <div className="dropdown-content">
                {!login ? (
                  <Fragment>
                    <Link to={"login"}>Giriş Yap</Link>
                    <Link to={"register"}>Kayıt Ol</Link>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Link to={"/profil/ozet"}>Profil</Link>
                    <button
                      className="btn"
                      onClick={() => dispatch(handleLogout())}
                    >
                      Çıkış Yap
                    </button>
                  </Fragment>
                )}
              </div>
            </div>
            <div className="dropdown">
              <button className="dropbtn d-flex align-items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M2.08416 2.7512C2.22155 2.36044 2.6497 2.15503 3.04047 2.29242L3.34187 2.39838C3.95839 2.61511 4.48203 2.79919 4.89411 3.00139C5.33474 3.21759 5.71259 3.48393 5.99677 3.89979C6.27875 4.31243 6.39517 4.76515 6.4489 5.26153C6.47295 5.48373 6.48564 5.72967 6.49233 6H17.1305C18.8155 6 20.3323 6 20.7762 6.57708C21.2202 7.15417 21.0466 8.02369 20.6995 9.76275L20.1997 12.1875C19.8846 13.7164 19.727 14.4808 19.1753 14.9304C18.6236 15.38 17.8431 15.38 16.2821 15.38H10.9792C8.19028 15.38 6.79583 15.38 5.92943 14.4662C5.06302 13.5523 4.99979 12.5816 4.99979 9.64L4.99979 7.03832C4.99979 6.29837 4.99877 5.80316 4.95761 5.42295C4.91828 5.0596 4.84858 4.87818 4.75832 4.74609C4.67026 4.61723 4.53659 4.4968 4.23336 4.34802C3.91052 4.18961 3.47177 4.03406 2.80416 3.79934L2.54295 3.7075C2.15218 3.57012 1.94678 3.14197 2.08416 2.7512Z"
                    fill="#1C274C"
                  />
                  <path
                    d="M7.5 18C8.32843 18 9 18.6716 9 19.5C9 20.3284 8.32843 21 7.5 21C6.67157 21 6 20.3284 6 19.5C6 18.6716 6.67157 18 7.5 18Z"
                    fill="#1C274C"
                  />
                  <path
                    d="M16.5 18.0001C17.3284 18.0001 18 18.6716 18 19.5001C18 20.3285 17.3284 21.0001 16.5 21.0001C15.6716 21.0001 15 20.3285 15 19.5001C15 18.6716 15.6716 18.0001 16.5 18.0001Z"
                    fill="#1C274C"
                  />
                </svg>
              </button>
              <div className="dropdown-content">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <img
                    src="https://cdn-icons-png.freepik.com/512/3900/3900098.png"
                    className="pt-4 pb-1"
                    width={50}
                  />
                  <p className="m-0 p-0 pb-3">Sepetiniz henüz boş</p>
                </div>
              </div>
            </div>
            <Link
              to={"/bagis-yap"}
              className="text-decoration-none bagis-yap-btn"
            >
              Bağış Yap
            </Link>
          </div>
        </header>

        <div className="mobile-header d-lg-none d-flex align-items-center justify-content-between">
          <Link href="home" className="text-decoration-none text-dark p-0 m-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="160"
              height="32"
              viewBox="0 0 160 32"
              fill="none"
            >
              <g clipPath="url(#clip0_2545_180)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20.2421 9.34824C18.9396 8.4692 17.4082 8 15.8416 8V0C18.9747 0 22.0375 0.938384 24.6427 2.69649C27.2478 4.45458 29.2783 6.95345 30.4773 9.87704C31.6763 12.8006 31.99 16.0178 31.3788 19.1214C30.7675 22.2251 29.2588 25.0761 27.0432 27.3137C24.8278 29.5514 22.0051 31.0752 18.9321 31.6926C15.8592 32.3099 12.6739 31.993 9.77925 30.7821C6.88459 29.571 4.41048 27.5203 2.66979 24.8891C0.929093 22.2579 0 19.1645 0 16H7.92079C7.92079 17.5822 8.38535 19.129 9.25568 20.4446C10.126 21.7602 11.3631 22.7855 12.8105 23.391C14.2577 23.9966 15.8504 24.155 17.3869 23.8462C18.9233 23.5376 20.3347 22.7757 21.4425 21.6569C22.5502 20.538 23.3046 19.1126 23.6101 17.5607C23.9158 16.0089 23.759 14.4003 23.1594 12.9386C22.5599 11.4767 21.5447 10.2273 20.2421 9.34824Z"
                  fill="#1C274C"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.92079 4.22794e-06C7.92079 1.05058 7.71592 2.09087 7.31786 3.06148C6.9198 4.03207 6.33636 4.91399 5.60084 5.65686C4.86533 6.39973 3.99215 6.989 3.03116 7.39104C2.07016 7.79308 1.04017 8 3.46226e-07 8L0 16C2.08034 16 4.14032 15.5862 6.06232 14.7821C7.98431 13.978 9.73069 12.7994 11.2017 11.3137C12.6727 9.828 13.8396 8.06416 14.6357 6.12294C15.4318 4.18172 15.8416 2.10115 15.8416 0L7.92079 4.22794e-06Z"
                  fill="#1C274C"
                />
                <path
                  d="M99.5002 11.562H95.657V23.8919H99.5002V11.562Z"
                  fill="#1C274C"
                />
                <path
                  d="M96.0848 9.25326C96.4864 9.65918 96.9886 9.86214 97.5913 9.86214C98.1941 9.86214 98.6884 9.65918 99.0733 9.25326C99.4591 8.83046 99.6515 8.32302 99.6515 7.73106C99.6515 7.12219 99.4591 6.61477 99.0733 6.20886C98.6884 5.80293 98.1941 5.59998 97.5913 5.59998C96.9886 5.59998 96.4864 5.80293 96.0848 6.20886C95.6991 6.61477 95.5066 7.12219 95.5066 7.73106C95.5066 8.32302 95.6991 8.83046 96.0848 9.25326Z"
                  fill="#1C274C"
                />
                <path
                  d="M40.5198 23.8918V5.98053H44.4635V20.3908H52.3257V23.8918H40.5198Z"
                  fill="#1C274C"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M59.4277 24.1709C58.1718 24.1709 57.0331 23.8919 56.0115 23.3337C55.0068 22.7587 54.2114 21.9807 53.6252 20.9996C53.0392 20.0187 52.7461 18.9193 52.7461 17.7015C52.7461 16.4838 53.0392 15.3929 53.6252 14.4288C54.2114 13.4647 55.0068 12.7036 56.0115 12.1455C57.0163 11.5704 58.155 11.2829 59.4277 11.2829C60.7004 11.2829 61.8391 11.562 62.8438 12.1201C63.8486 12.6783 64.6441 13.4479 65.2301 14.4288C65.8163 15.3929 66.1093 16.4838 66.1093 17.7015C66.1093 18.9193 65.8163 20.0187 65.2301 20.9996C64.6441 21.9807 63.8486 22.7587 62.8438 23.3337C61.8391 23.8919 60.7004 24.1709 59.4277 24.1709ZM59.4277 20.6445C59.9803 20.6445 60.4659 20.5261 60.8846 20.2893C61.3032 20.0356 61.6214 19.6889 61.8391 19.2491C62.0735 18.7925 62.1908 18.2766 62.1908 17.7015C62.1908 17.1265 62.0735 16.6275 61.8391 16.2047C61.6046 15.765 61.2781 15.4267 60.8594 15.1899C60.4575 14.9362 59.9803 14.8094 59.4277 14.8094C58.8918 14.8094 58.4146 14.9362 57.9959 15.1899C57.5773 15.4267 57.2507 15.765 57.0163 16.2047C56.7818 16.6445 56.6647 17.1519 56.6647 17.7269C56.6647 18.2851 56.7818 18.7925 57.0163 19.2491C57.2507 19.6889 57.5773 20.0356 57.9959 20.2893C58.4146 20.5261 58.8918 20.6445 59.4277 20.6445Z"
                  fill="#1C274C"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M69.1531 28.7121C70.1745 29.1857 71.3551 29.4225 72.6948 29.4225C74.001 29.4225 75.1649 29.1603 76.1863 28.636C77.2079 28.1117 78.0116 27.3845 78.5977 26.4542C79.1838 25.524 79.4773 24.4246 79.4773 23.1561V11.562H75.709V12.3904C75.4288 12.1521 75.1105 11.9521 74.7546 11.7903C74.1014 11.4689 73.3562 11.3082 72.519 11.3082C71.397 11.3082 70.4006 11.5789 69.5299 12.1201C68.659 12.6444 67.9725 13.3717 67.4701 14.3019C66.9676 15.2153 66.7166 16.2469 66.7166 17.3971C66.7166 18.5303 66.9676 19.562 67.4701 20.4922C67.9725 21.4225 68.659 22.1582 69.5299 22.6994C70.4006 23.2406 71.397 23.5113 72.519 23.5113C73.3395 23.5113 74.0847 23.3506 74.7546 23.0293C75.079 22.8736 75.3721 22.6901 75.6337 22.4789V23.2576C75.6337 24.1201 75.3574 24.7882 74.8048 25.2618C74.269 25.7354 73.5238 25.9721 72.5692 25.9721C71.7989 25.9721 71.1375 25.8369 70.5848 25.5662C70.049 25.2957 69.5633 24.8981 69.1279 24.3738L66.7668 26.7586C67.3528 27.6043 68.1483 28.2555 69.1531 28.7121ZM74.629 19.7057C74.2438 19.9425 73.7833 20.0609 73.2474 20.0609C72.7116 20.0609 72.2427 19.9425 71.8408 19.7057C71.4556 19.4689 71.1542 19.1476 70.9365 18.7417C70.7188 18.3189 70.6099 17.8706 70.6099 17.3971C70.6099 16.8897 70.7188 16.433 70.9365 16.0271C71.1542 15.6212 71.464 15.2998 71.8659 15.063C72.2678 14.8262 72.7283 14.7078 73.2474 14.7078C73.7833 14.7078 74.2438 14.8262 74.629 15.063C75.0309 15.2998 75.3322 15.6212 75.5333 16.0271C75.7509 16.433 75.8598 16.8897 75.8598 17.3971C75.8598 17.9045 75.7509 18.3611 75.5333 18.767C75.3322 19.1561 75.0309 19.4689 74.629 19.7057Z"
                  fill="#1C274C"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M87.565 24.1709C86.3088 24.1709 85.1698 23.8919 84.1488 23.3337C83.1437 22.7587 82.3484 21.9807 81.7623 20.9996C81.1761 20.0187 80.8831 18.9193 80.8831 17.7015C80.8831 16.4838 81.1761 15.3929 81.7623 14.4288C82.3484 13.4647 83.1437 12.7036 84.1488 12.1455C85.1532 11.5704 86.2922 11.2829 87.565 11.2829C88.8371 11.2829 89.9761 11.562 90.9805 12.1201C91.9856 12.6783 92.7809 13.4479 93.367 14.4288C93.9532 15.3929 94.2462 16.4838 94.2462 17.7015C94.2462 18.9193 93.9532 20.0187 93.367 20.9996C92.7809 21.9807 91.9856 22.7587 90.9805 23.3337C89.9761 23.8919 88.8371 24.1709 87.565 24.1709ZM87.565 20.6445C88.1171 20.6445 88.6027 20.5261 89.0217 20.2893C89.4399 20.0356 89.7583 19.6889 89.9761 19.2491C90.2106 18.7925 90.3278 18.2766 90.3278 17.7015C90.3278 17.1265 90.2106 16.6275 89.9761 16.2047C89.7417 15.765 89.4153 15.4267 88.9963 15.1899C88.5947 14.9362 88.1171 14.8094 87.565 14.8094C87.0288 14.8094 86.5512 14.9362 86.133 15.1899C85.7139 15.4267 85.3876 15.765 85.1532 16.2047C84.9187 16.6445 84.8015 17.1519 84.8015 17.7269C84.8015 18.2851 84.9187 18.7925 85.1532 19.2491C85.3876 19.6889 85.7139 20.0356 86.133 20.2893C86.5512 20.5261 87.0288 20.6445 87.565 20.6445Z"
                  fill="#1C274C"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M108.737 24.1455C107.918 24.1455 107.164 23.9849 106.477 23.6635C106.116 23.4944 105.789 23.2925 105.498 23.0577V29.0927H101.705V11.562H105.548V12.4129C105.827 12.1826 106.136 11.9835 106.477 11.8157C107.164 11.4773 107.918 11.3082 108.737 11.3082C109.893 11.3082 110.915 11.5873 111.802 12.1455C112.707 12.7036 113.41 13.4647 113.912 14.4288C114.432 15.3929 114.691 16.4922 114.691 17.7269C114.691 18.9616 114.432 20.0609 113.912 21.025C113.41 21.9891 112.707 22.7501 111.802 23.3083C110.915 23.8665 109.893 24.1455 108.737 24.1455ZM108.035 20.6445C108.587 20.6445 109.065 20.5176 109.466 20.2639C109.885 20.0102 110.212 19.6634 110.446 19.2237C110.68 18.784 110.798 18.285 110.798 17.7269C110.798 17.1518 110.68 16.6445 110.446 16.2047C110.212 15.7649 109.885 15.4267 109.466 15.1899C109.065 14.9361 108.596 14.8093 108.059 14.8093C107.524 14.8093 107.046 14.9361 106.628 15.1899C106.226 15.4267 105.908 15.7649 105.674 16.2047C105.439 16.6445 105.322 17.1518 105.322 17.7269C105.322 18.285 105.431 18.784 105.648 19.2237C105.883 19.6634 106.209 20.0102 106.628 20.2639C107.046 20.5176 107.515 20.6445 108.035 20.6445Z"
                  fill="#1C274C"
                />
                <path
                  d="M118.452 23.9172C119.156 24.1033 119.867 24.1962 120.587 24.1962C122.111 24.1962 123.316 23.8411 124.204 23.1307C125.108 22.4204 125.56 21.4648 125.56 20.2639C125.56 19.4859 125.419 18.8601 125.134 18.3865C124.849 17.8961 124.481 17.507 124.029 17.2195C123.576 16.932 123.099 16.7036 122.596 16.5345C122.094 16.3653 121.617 16.2216 121.165 16.1032C120.713 15.9848 120.345 15.8495 120.059 15.6973C119.775 15.545 119.633 15.3421 119.633 15.0884C119.633 14.8516 119.742 14.674 119.959 14.5556C120.177 14.4203 120.503 14.3527 120.939 14.3527C121.391 14.3527 121.851 14.4457 122.32 14.6317C122.806 14.8177 123.241 15.1392 123.626 15.5958L125.812 13.3633C125.259 12.6529 124.539 12.1201 123.652 11.7649C122.781 11.3929 121.827 11.2068 120.788 11.2068C119.8 11.2068 118.938 11.3759 118.201 11.7141C117.464 12.0525 116.894 12.5176 116.493 13.1095C116.091 13.6845 115.89 14.3696 115.89 15.1645C115.89 15.9087 116.033 16.5261 116.317 17.0165C116.601 17.4901 116.97 17.8622 117.422 18.1328C117.874 18.4034 118.352 18.6149 118.854 18.767C119.356 18.9193 119.834 19.063 120.286 19.1984C120.737 19.3168 121.107 19.4605 121.391 19.6297C121.693 19.7819 121.843 20.0102 121.843 20.3146C121.843 20.5514 121.717 20.7374 121.466 20.8728C121.232 21.0081 120.889 21.0757 120.436 21.0757C119.783 21.0757 119.18 20.9573 118.628 20.7205C118.075 20.4669 117.598 20.1201 117.196 19.6804L115.011 21.9129C115.43 22.3696 115.932 22.7756 116.518 23.1307C117.121 23.4689 117.766 23.7311 118.452 23.9172Z"
                  fill="#1C274C"
                />
                <path
                  d="M132.569 24.1709C131.447 24.1709 130.451 23.9426 129.58 23.4859C128.726 23.0123 128.056 22.3696 127.571 21.5578C127.084 20.7291 126.842 19.7819 126.842 18.7163V11.562H130.685V18.6656C130.685 19.0884 130.752 19.4521 130.886 19.7565C131.037 20.061 131.255 20.2978 131.539 20.4669C131.824 20.636 132.167 20.7206 132.569 20.7206C133.138 20.7206 133.591 20.543 133.926 20.1878C134.261 19.8157 134.428 19.3083 134.428 18.6656V11.562H138.271V18.691C138.271 19.7735 138.029 20.7291 137.542 21.5578C137.057 22.3696 136.387 23.0123 135.533 23.4859C134.679 23.9426 133.691 24.1709 132.569 24.1709Z"
                  fill="#1C274C"
                />
                <path
                  d="M144.025 11.562H140.181V23.8918H144.025V16.6867C144.025 16.2639 144.109 15.9087 144.276 15.6212C144.46 15.3337 144.703 15.1137 145.004 14.9616C145.306 14.7924 145.649 14.7078 146.034 14.7078C146.587 14.7078 147.047 14.8854 147.415 15.2406C147.801 15.5789 147.994 16.0609 147.994 16.6867V23.8918H151.837V16.6867C151.837 16.2639 151.921 15.9087 152.088 15.6212C152.272 15.3337 152.515 15.1137 152.817 14.9616C153.118 14.7924 153.461 14.7078 153.846 14.7078C154.399 14.7078 154.859 14.8854 155.228 15.2406C155.613 15.5789 155.805 16.0609 155.805 16.6867V23.8918H159.649V16.2554C159.649 15.2237 159.431 14.3442 158.996 13.6169C158.577 12.8727 157.999 12.3061 157.263 11.9172C156.543 11.5112 155.713 11.3082 154.775 11.3082C153.821 11.3082 152.958 11.5197 152.189 11.9425C151.688 12.2119 151.251 12.5596 150.876 12.9855C150.527 12.54 150.102 12.1754 149.601 11.8917C148.931 11.5028 148.17 11.3082 147.316 11.3082C146.411 11.3082 145.599 11.5028 144.879 11.8917C144.566 12.0536 144.281 12.2474 144.025 12.4733V11.562Z"
                  fill="#1C274C"
                />
              </g>
              <defs>
                <clipPath id="clip0_2545_180">
                  <rect width="160" height="32" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Link>
          <button className="btn border-0" onClick={() => setCanvas(!canvas)}>
            <HiMiniBars3BottomRight />
          </button>
          <Offcanvas
            isOpen={canvas}
            toggle={() => setCanvas(!canvas)}
            direction="end"
          >
            <OffcanvasHeader toggle={() => setCanvas(!canvas)}>
              <Link to={""}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="160"
                  height="32"
                  viewBox="0 0 160 32"
                  fill="none"
                >
                  <g clip-path="url(#clip0_2545_180)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M20.2421 9.34824C18.9396 8.4692 17.4082 8 15.8416 8V0C18.9747 0 22.0375 0.938384 24.6427 2.69649C27.2478 4.45458 29.2783 6.95345 30.4773 9.87704C31.6763 12.8006 31.99 16.0178 31.3788 19.1214C30.7675 22.2251 29.2588 25.0761 27.0432 27.3137C24.8278 29.5514 22.0051 31.0752 18.9321 31.6926C15.8592 32.3099 12.6739 31.993 9.77925 30.7821C6.88459 29.571 4.41048 27.5203 2.66979 24.8891C0.929093 22.2579 0 19.1645 0 16H7.92079C7.92079 17.5822 8.38535 19.129 9.25568 20.4446C10.126 21.7602 11.3631 22.7855 12.8105 23.391C14.2577 23.9966 15.8504 24.155 17.3869 23.8462C18.9233 23.5376 20.3347 22.7757 21.4425 21.6569C22.5502 20.538 23.3046 19.1126 23.6101 17.5607C23.9158 16.0089 23.759 14.4003 23.1594 12.9386C22.5599 11.4767 21.5447 10.2273 20.2421 9.34824Z"
                      fill="#1C274C"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.92079 4.22794e-06C7.92079 1.05058 7.71592 2.09087 7.31786 3.06148C6.9198 4.03207 6.33636 4.91399 5.60084 5.65686C4.86533 6.39973 3.99215 6.989 3.03116 7.39104C2.07016 7.79308 1.04017 8 3.46226e-07 8L0 16C2.08034 16 4.14032 15.5862 6.06232 14.7821C7.98431 13.978 9.73069 12.7994 11.2017 11.3137C12.6727 9.828 13.8396 8.06416 14.6357 6.12294C15.4318 4.18172 15.8416 2.10115 15.8416 0L7.92079 4.22794e-06Z"
                      fill="#1C274C"
                    />
                    <path
                      d="M99.5002 11.562H95.657V23.8919H99.5002V11.562Z"
                      fill="#1C274C"
                    />
                    <path
                      d="M96.0848 9.25326C96.4864 9.65918 96.9886 9.86214 97.5913 9.86214C98.1941 9.86214 98.6884 9.65918 99.0733 9.25326C99.4591 8.83046 99.6515 8.32302 99.6515 7.73106C99.6515 7.12219 99.4591 6.61477 99.0733 6.20886C98.6884 5.80293 98.1941 5.59998 97.5913 5.59998C96.9886 5.59998 96.4864 5.80293 96.0848 6.20886C95.6991 6.61477 95.5066 7.12219 95.5066 7.73106C95.5066 8.32302 95.6991 8.83046 96.0848 9.25326Z"
                      fill="#1C274C"
                    />
                    <path
                      d="M40.5198 23.8918V5.98053H44.4635V20.3908H52.3257V23.8918H40.5198Z"
                      fill="#1C274C"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M59.4277 24.1709C58.1718 24.1709 57.0331 23.8919 56.0115 23.3337C55.0068 22.7587 54.2114 21.9807 53.6252 20.9996C53.0392 20.0187 52.7461 18.9193 52.7461 17.7015C52.7461 16.4838 53.0392 15.3929 53.6252 14.4288C54.2114 13.4647 55.0068 12.7036 56.0115 12.1455C57.0163 11.5704 58.155 11.2829 59.4277 11.2829C60.7004 11.2829 61.8391 11.562 62.8438 12.1201C63.8486 12.6783 64.6441 13.4479 65.2301 14.4288C65.8163 15.3929 66.1093 16.4838 66.1093 17.7015C66.1093 18.9193 65.8163 20.0187 65.2301 20.9996C64.6441 21.9807 63.8486 22.7587 62.8438 23.3337C61.8391 23.8919 60.7004 24.1709 59.4277 24.1709ZM59.4277 20.6445C59.9803 20.6445 60.4659 20.5261 60.8846 20.2893C61.3032 20.0356 61.6214 19.6889 61.8391 19.2491C62.0735 18.7925 62.1908 18.2766 62.1908 17.7015C62.1908 17.1265 62.0735 16.6275 61.8391 16.2047C61.6046 15.765 61.2781 15.4267 60.8594 15.1899C60.4575 14.9362 59.9803 14.8094 59.4277 14.8094C58.8918 14.8094 58.4146 14.9362 57.9959 15.1899C57.5773 15.4267 57.2507 15.765 57.0163 16.2047C56.7818 16.6445 56.6647 17.1519 56.6647 17.7269C56.6647 18.2851 56.7818 18.7925 57.0163 19.2491C57.2507 19.6889 57.5773 20.0356 57.9959 20.2893C58.4146 20.5261 58.8918 20.6445 59.4277 20.6445Z"
                      fill="#1C274C"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M69.1531 28.7121C70.1745 29.1857 71.3551 29.4225 72.6948 29.4225C74.001 29.4225 75.1649 29.1603 76.1863 28.636C77.2079 28.1117 78.0116 27.3845 78.5977 26.4542C79.1838 25.524 79.4773 24.4246 79.4773 23.1561V11.562H75.709V12.3904C75.4288 12.1521 75.1105 11.9521 74.7546 11.7903C74.1014 11.4689 73.3562 11.3082 72.519 11.3082C71.397 11.3082 70.4006 11.5789 69.5299 12.1201C68.659 12.6444 67.9725 13.3717 67.4701 14.3019C66.9676 15.2153 66.7166 16.2469 66.7166 17.3971C66.7166 18.5303 66.9676 19.562 67.4701 20.4922C67.9725 21.4225 68.659 22.1582 69.5299 22.6994C70.4006 23.2406 71.397 23.5113 72.519 23.5113C73.3395 23.5113 74.0847 23.3506 74.7546 23.0293C75.079 22.8736 75.3721 22.6901 75.6337 22.4789V23.2576C75.6337 24.1201 75.3574 24.7882 74.8048 25.2618C74.269 25.7354 73.5238 25.9721 72.5692 25.9721C71.7989 25.9721 71.1375 25.8369 70.5848 25.5662C70.049 25.2957 69.5633 24.8981 69.1279 24.3738L66.7668 26.7586C67.3528 27.6043 68.1483 28.2555 69.1531 28.7121ZM74.629 19.7057C74.2438 19.9425 73.7833 20.0609 73.2474 20.0609C72.7116 20.0609 72.2427 19.9425 71.8408 19.7057C71.4556 19.4689 71.1542 19.1476 70.9365 18.7417C70.7188 18.3189 70.6099 17.8706 70.6099 17.3971C70.6099 16.8897 70.7188 16.433 70.9365 16.0271C71.1542 15.6212 71.464 15.2998 71.8659 15.063C72.2678 14.8262 72.7283 14.7078 73.2474 14.7078C73.7833 14.7078 74.2438 14.8262 74.629 15.063C75.0309 15.2998 75.3322 15.6212 75.5333 16.0271C75.7509 16.433 75.8598 16.8897 75.8598 17.3971C75.8598 17.9045 75.7509 18.3611 75.5333 18.767C75.3322 19.1561 75.0309 19.4689 74.629 19.7057Z"
                      fill="#1C274C"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M87.565 24.1709C86.3088 24.1709 85.1698 23.8919 84.1488 23.3337C83.1437 22.7587 82.3484 21.9807 81.7623 20.9996C81.1761 20.0187 80.8831 18.9193 80.8831 17.7015C80.8831 16.4838 81.1761 15.3929 81.7623 14.4288C82.3484 13.4647 83.1437 12.7036 84.1488 12.1455C85.1532 11.5704 86.2922 11.2829 87.565 11.2829C88.8371 11.2829 89.9761 11.562 90.9805 12.1201C91.9856 12.6783 92.7809 13.4479 93.367 14.4288C93.9532 15.3929 94.2462 16.4838 94.2462 17.7015C94.2462 18.9193 93.9532 20.0187 93.367 20.9996C92.7809 21.9807 91.9856 22.7587 90.9805 23.3337C89.9761 23.8919 88.8371 24.1709 87.565 24.1709ZM87.565 20.6445C88.1171 20.6445 88.6027 20.5261 89.0217 20.2893C89.4399 20.0356 89.7583 19.6889 89.9761 19.2491C90.2106 18.7925 90.3278 18.2766 90.3278 17.7015C90.3278 17.1265 90.2106 16.6275 89.9761 16.2047C89.7417 15.765 89.4153 15.4267 88.9963 15.1899C88.5947 14.9362 88.1171 14.8094 87.565 14.8094C87.0288 14.8094 86.5512 14.9362 86.133 15.1899C85.7139 15.4267 85.3876 15.765 85.1532 16.2047C84.9187 16.6445 84.8015 17.1519 84.8015 17.7269C84.8015 18.2851 84.9187 18.7925 85.1532 19.2491C85.3876 19.6889 85.7139 20.0356 86.133 20.2893C86.5512 20.5261 87.0288 20.6445 87.565 20.6445Z"
                      fill="#1C274C"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M108.737 24.1455C107.918 24.1455 107.164 23.9849 106.477 23.6635C106.116 23.4944 105.789 23.2925 105.498 23.0577V29.0927H101.705V11.562H105.548V12.4129C105.827 12.1826 106.136 11.9835 106.477 11.8157C107.164 11.4773 107.918 11.3082 108.737 11.3082C109.893 11.3082 110.915 11.5873 111.802 12.1455C112.707 12.7036 113.41 13.4647 113.912 14.4288C114.432 15.3929 114.691 16.4922 114.691 17.7269C114.691 18.9616 114.432 20.0609 113.912 21.025C113.41 21.9891 112.707 22.7501 111.802 23.3083C110.915 23.8665 109.893 24.1455 108.737 24.1455ZM108.035 20.6445C108.587 20.6445 109.065 20.5176 109.466 20.2639C109.885 20.0102 110.212 19.6634 110.446 19.2237C110.68 18.784 110.798 18.285 110.798 17.7269C110.798 17.1518 110.68 16.6445 110.446 16.2047C110.212 15.7649 109.885 15.4267 109.466 15.1899C109.065 14.9361 108.596 14.8093 108.059 14.8093C107.524 14.8093 107.046 14.9361 106.628 15.1899C106.226 15.4267 105.908 15.7649 105.674 16.2047C105.439 16.6445 105.322 17.1518 105.322 17.7269C105.322 18.285 105.431 18.784 105.648 19.2237C105.883 19.6634 106.209 20.0102 106.628 20.2639C107.046 20.5176 107.515 20.6445 108.035 20.6445Z"
                      fill="#1C274C"
                    />
                    <path
                      d="M118.452 23.9172C119.156 24.1033 119.867 24.1962 120.587 24.1962C122.111 24.1962 123.316 23.8411 124.204 23.1307C125.108 22.4204 125.56 21.4648 125.56 20.2639C125.56 19.4859 125.419 18.8601 125.134 18.3865C124.849 17.8961 124.481 17.507 124.029 17.2195C123.576 16.932 123.099 16.7036 122.596 16.5345C122.094 16.3653 121.617 16.2216 121.165 16.1032C120.713 15.9848 120.345 15.8495 120.059 15.6973C119.775 15.545 119.633 15.3421 119.633 15.0884C119.633 14.8516 119.742 14.674 119.959 14.5556C120.177 14.4203 120.503 14.3527 120.939 14.3527C121.391 14.3527 121.851 14.4457 122.32 14.6317C122.806 14.8177 123.241 15.1392 123.626 15.5958L125.812 13.3633C125.259 12.6529 124.539 12.1201 123.652 11.7649C122.781 11.3929 121.827 11.2068 120.788 11.2068C119.8 11.2068 118.938 11.3759 118.201 11.7141C117.464 12.0525 116.894 12.5176 116.493 13.1095C116.091 13.6845 115.89 14.3696 115.89 15.1645C115.89 15.9087 116.033 16.5261 116.317 17.0165C116.601 17.4901 116.97 17.8622 117.422 18.1328C117.874 18.4034 118.352 18.6149 118.854 18.767C119.356 18.9193 119.834 19.063 120.286 19.1984C120.737 19.3168 121.107 19.4605 121.391 19.6297C121.693 19.7819 121.843 20.0102 121.843 20.3146C121.843 20.5514 121.717 20.7374 121.466 20.8728C121.232 21.0081 120.889 21.0757 120.436 21.0757C119.783 21.0757 119.18 20.9573 118.628 20.7205C118.075 20.4669 117.598 20.1201 117.196 19.6804L115.011 21.9129C115.43 22.3696 115.932 22.7756 116.518 23.1307C117.121 23.4689 117.766 23.7311 118.452 23.9172Z"
                      fill="#1C274C"
                    />
                    <path
                      d="M132.569 24.1709C131.447 24.1709 130.451 23.9426 129.58 23.4859C128.726 23.0123 128.056 22.3696 127.571 21.5578C127.084 20.7291 126.842 19.7819 126.842 18.7163V11.562H130.685V18.6656C130.685 19.0884 130.752 19.4521 130.886 19.7565C131.037 20.061 131.255 20.2978 131.539 20.4669C131.824 20.636 132.167 20.7206 132.569 20.7206C133.138 20.7206 133.591 20.543 133.926 20.1878C134.261 19.8157 134.428 19.3083 134.428 18.6656V11.562H138.271V18.691C138.271 19.7735 138.029 20.7291 137.542 21.5578C137.057 22.3696 136.387 23.0123 135.533 23.4859C134.679 23.9426 133.691 24.1709 132.569 24.1709Z"
                      fill="#1C274C"
                    />
                    <path
                      d="M144.025 11.562H140.181V23.8918H144.025V16.6867C144.025 16.2639 144.109 15.9087 144.276 15.6212C144.46 15.3337 144.703 15.1137 145.004 14.9616C145.306 14.7924 145.649 14.7078 146.034 14.7078C146.587 14.7078 147.047 14.8854 147.415 15.2406C147.801 15.5789 147.994 16.0609 147.994 16.6867V23.8918H151.837V16.6867C151.837 16.2639 151.921 15.9087 152.088 15.6212C152.272 15.3337 152.515 15.1137 152.817 14.9616C153.118 14.7924 153.461 14.7078 153.846 14.7078C154.399 14.7078 154.859 14.8854 155.228 15.2406C155.613 15.5789 155.805 16.0609 155.805 16.6867V23.8918H159.649V16.2554C159.649 15.2237 159.431 14.3442 158.996 13.6169C158.577 12.8727 157.999 12.3061 157.263 11.9172C156.543 11.5112 155.713 11.3082 154.775 11.3082C153.821 11.3082 152.958 11.5197 152.189 11.9425C151.688 12.2119 151.251 12.5596 150.876 12.9855C150.527 12.54 150.102 12.1754 149.601 11.8917C148.931 11.5028 148.17 11.3082 147.316 11.3082C146.411 11.3082 145.599 11.5028 144.879 11.8917C144.566 12.0536 144.281 12.2474 144.025 12.4733V11.562Z"
                      fill="#1C274C"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2545_180">
                      <rect width="160" height="32" fill="white" />
                    </clipPath>
                  </defs>
                </svg>{" "}
              </Link>
            </OffcanvasHeader>
            <OffcanvasBody>
              <Accordion
                open={open}
                toggle={toggles}
                className="accordions-mobile-header"
              >
                <AccordionItem>
                  <AccordionHeader
                    targetId="1"
                    className="fw-700 fs-16 lh-lg-3"
                  >
                    Kurumsal
                  </AccordionHeader>
                  <AccordionBody accordionId="1">
                    <ul className="p-0 m-0">
                      <li className="ms-4 mb-3">
                        <Link
                          to={""}
                          className="text-decoration-none text-blue fw-600 fs-14"
                        >
                          Hakkımızda
                        </Link>
                      </li>
                      <li className="ms-4 mb-3">
                        <Link
                          to={""}
                          className="text-decoration-none text-blue fw-600 fs-14"
                        >
                          Değerlerimiz
                        </Link>
                      </li>
                      <li className="ms-4 mb-3">
                        <Link
                          to={""}
                          className="text-decoration-none text-blue fw-600 fs-14"
                        >
                          İnsan Kaynakları
                        </Link>
                      </li>
                      <li className="ms-4 mb-3">
                        <Link
                          to={""}
                          className="text-decoration-none text-blue fw-600 fs-14"
                        >
                          Basın Odası
                        </Link>
                      </li>
                      <li className="ms-4 mb-3">
                        <Link
                          to={""}
                          className="text-decoration-none text-blue fw-600 fs-14"
                        >
                          Yönetim Kurulu
                        </Link>
                      </li>
                      <li className="ms-4 mb-3">
                        <Link
                          to={""}
                          className="text-decoration-none text-blue fw-600 fs-14"
                        >
                          Bilgilendirme
                        </Link>
                      </li>
                      <li className="ms-4 mb-3">
                        <Link
                          to={""}
                          className="text-decoration-none text-blue fw-600 fs-14"
                        >
                          Sıkça Sorulan Sorular
                        </Link>
                      </li>
                    </ul>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="2">
                    Faaliyetlerimiz
                  </AccordionHeader>
                  <AccordionBody accordionId="2">
                    <ul className="p-0 m-0">
                      <li className="ms-4 mb-3">
                        <Link
                          to={"/faaliyetlerimiz"}
                          className="text-decoration-none text-blue fw-600 fs-14"
                        >
                          Arama Kurtarma
                        </Link>
                      </li>
                    </ul>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="3">Projelerimiz</AccordionHeader>
                  <AccordionBody accordionId="3">
                    <ul className="p-0 m-0">
                      <li className="ms-4 mb-3">
                        <Link
                          to={"/projelerimiz"}
                          className="text-decoration-none text-blue fw-600 fs-14"
                        >
                          Tekerlekli Sandalye
                        </Link>
                      </li>
                      <li className="ms-4 mb-3">
                        <Link
                          to={"/projelerimiz"}
                          className="text-decoration-none text-blue fw-600 fs-14"
                        >
                          Ekmek Yardımı
                        </Link>
                      </li>
                      <li className="ms-4 mb-3">
                        <Link
                          to={"/projelerimiz"}
                          className="text-decoration-none text-blue fw-600 fs-14"
                        >
                          Battaniye Yardımı
                        </Link>
                      </li>
                    </ul>
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            </OffcanvasBody>
          </Offcanvas>
        </div>
      </div>
    </div>
  );
};

export default Header;
