import React from "react";
import Tabs from "../Components/Tabs";

const BagisYap = () => {
  return (
    <div>
      <Tabs />
      <div className="category-content container-fluid">
        <div className="row">
          <div className="col-lg-9 col-12">
            <div className="bagis-yap">
              <p className="m-0 p-0 montserrat fs-32 fw-700">Gazze Bağışı</p>
              <p
                style={{
                  borderBottom: "2px solid #2B4141",
                  width: "100%",
                  height: "2px",
                  marginTop: "11px",
                }}
              ></p>
            </div>
            <div className="row my-lg-5">
              <div className="col-lg-4 col-md-6 col-12 mb-lg-5 mb-4 w-auto">
                <div className="help-card" style={{ width: " 18rem" }}>
                  <img
                    src="https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1490000/yemen-aa-1490262.jpg"
                    className="card-img-top "
                    alt="img"
                    style={{
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  />
                  <div className="card-body p-2">
                    <h5 className="help-title">Mama Paketi</h5>
                    <div className="row">
                      <div className="col-lg-6 position-relative mb-2">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="0"
                          />
                          <button className="btn border-0 position-absolute mini-green-btn mini-green-absolute">
                            TL
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-6 position-relative  mb-2">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="0"
                          />
                          <button className="btn border-0 position-absolute mini-green-btn mini-green-absolute">
                            Adet
                          </button>
                        </div>
                      </div>
                    </div>
                    <a
                      href="kategoriler-1.html"
                      className="btn bg-dark-green w-100 text-light-green p-3 mb-2"
                    >
                      Bağış Yap
                    </a>
                    <a
                      href="detayli-bilgi.html"
                      className="btn outline-dark-green w-100 p-3"
                    >
                      Detaylı Bilgi
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mb-lg-5 mb-4 w-auto">
                <div className="help-card" style={{ width: " 18rem" }}>
                  <img
                    src="https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1490000/yemen-aa-1490262.jpg"
                    className="card-img-top "
                    alt="img"
                    style={{
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  />
                  <div className="card-body p-2">
                    <h5 className="help-title">Mama Paketi</h5>
                    <div className="row">
                      <div className="col-lg-6 position-relative mb-2">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="0"
                          />
                          <button className="btn border-0 position-absolute mini-green-btn mini-green-absolute">
                            TL
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-6 position-relative  mb-2">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="0"
                          />
                          <button className="btn border-0 position-absolute mini-green-btn mini-green-absolute">
                            Adet
                          </button>
                        </div>
                      </div>
                    </div>
                    <a
                      href="kategoriler-1.html"
                      className="btn bg-dark-green w-100 text-light-green p-3 mb-2"
                    >
                      Bağış Yap
                    </a>
                    <a
                      href="detayli-bilgi.html"
                      className="btn outline-dark-green w-100 p-3"
                    >
                      Detaylı Bilgi
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mb-lg-5 mb-4 w-auto">
                <div className="help-card" style={{ width: " 18rem" }}>
                  <img
                    src="https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1490000/yemen-aa-1490262.jpg"
                    className="card-img-top "
                    alt="img"
                    style={{
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  />
                  <div className="card-body p-2">
                    <h5 className="help-title">Afrika Su Kuyusu</h5>
                    <div className="row">
                      <div className="col-lg-6 position-relative mb-2">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="0"
                          />
                          <button className="btn border-0 position-absolute mini-green-btn mini-green-absolute">
                            TL
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-6 position-relative  mb-2">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="0"
                          />
                          <button className="btn border-0 position-absolute mini-green-btn mini-green-absolute">
                            Adet
                          </button>
                        </div>
                      </div>
                    </div>
                    <a
                      href="kategoriler-1.html"
                      className="btn bg-dark-green w-100 text-light-green p-3 mb-2"
                    >
                      Bağış Yap
                    </a>
                    <a
                      href="detayli-bilgi.html"
                      className="btn outline-dark-green w-100 p-3"
                    >
                      Detaylı Bilgi
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="bagis-yap">
              <p className="m-0 p-0 montserrat fs-32 fw-700">Kurban Bağışı</p>
              <p
                style={{
                  borderBottom: "2px solid #2B4141",
                  width: "100%",
                  height: "2px",
                  marginTop: "11px",
                }}
              ></p>
            </div>
            <div className="col-lg-4  col-12 mt-lg-5 mt-3 mb-lg-5 mb-3">
              <div className="help-card" style={{ width: "18rem" }}>
                <img
                  src="https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1490000/yemen-aa-1490262.jpg"
                  className="card-img-top "
                  alt="img"
                  style={{
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                />
                <div className="card-body p-2">
                  <h5 className="help-title">Genel Bağış</h5>
                  <div className="row">
                    <div className="col-lg-12 mb-2">
                      <select
                        className="form-select form-select-lg mb-2 fs-16 fw-400"
                        aria-label="Large select example"
                      >
                        <option selected>Küçükbaş</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="col-lg-12 mb-2">
                      <select
                        className="form-select form-select-lg mb-2 fs-16 fw-400"
                        aria-label="Large select example"
                      >
                        <option selected>Afrika</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="col-lg-12 mb-2">
                      <select
                        className="form-select form-select-lg mb-2 fs-16 fw-400"
                        aria-label="Large select example"
                      >
                        <option selected>Adak Kurbanı</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="col-lg-6 position-relative mb-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="0"
                      />
                      <button
                        className="btn border-0 position-absolute mini-green-btn"
                        style={{ top: "1px", right: "10px", width: "30%" }}
                      >
                        TL
                      </button>
                    </div>
                    <div className="col-lg-6 position-relative mb-2">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="0"
                      />
                      <button
                        className="btn border-0 position-absolute mini-green-btn"
                        style={{ top: "1px", right: "10px", width: "30%" }}
                      >
                        Adet
                      </button>
                    </div>
                  </div>
                  <a
                    href="#"
                    className="btn bg-dark-green w-100 text-light-green mb-2"
                  >
                    Bağış Yap
                  </a>
                  <a href="#" className="btn outline-dark-green w-100">
                    Detaylı Bilgi
                  </a>
                </div>
              </div>
            </div>
            <div className="bagis-yap">
              <p className="m-0 p-0 montserrat fs-32 fw-700">Yetim Maaşı</p>
              <p
                style={{
                  borderBottom: "2px solid #2B4141",
                  width: "100%",
                  height: "2px",
                  marginTop: "11px",
                }}
              ></p>
            </div>
            <div className="row my-lg-5">
              <div className="col-lg-4 col-md-6 col-12 mb-lg-5 mb-4 w-auto">
                <div className="help-card">
                  <img
                    src="https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1490000/yemen-aa-1490262.jpg"
                    className="card-img-top "
                    alt="img"
                    style={{
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  />
                  <div className="card-body p-2">
                    <h5 className="help-title">Yetim Maaşı</h5>
                    <div className="row">
                      <div className="col-lg-12 position-relative mb-2">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="0"
                          />
                          <button className="btn border-0 position-absolute mini-green-btn mini-green-absolute">
                            TL
                          </button>
                        </div>
                      </div>
                    </div>
                    <a
                      href="kategoriler-1.html"
                      className="btn bg-dark-green w-100 text-light-green p-3 mb-2"
                    >
                      Bağış Yap
                    </a>
                    <a
                      href="detayli-bilgi.html"
                      className="btn outline-dark-green w-100 p-3"
                    >
                      Detaylı Bilgi
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mb-lg-5 mb-4 w-auto">
                <div className="help-card">
                  <img
                    src="https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1490000/yemen-aa-1490262.jpg"
                    className="card-img-top "
                    alt="img"
                    style={{
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  />
                  <div className="card-body p-2">
                    <h5 className="help-title">Yetim Maaşı</h5>
                    <div className="row">
                      <div className="col-lg-12 position-relative mb-2">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="0"
                          />
                          <button className="btn border-0 position-absolute mini-green-btn mini-green-absolute">
                            TL
                          </button>
                        </div>
                      </div>
                    </div>
                    <a
                      href="kategoriler-1.html"
                      className="btn bg-dark-green w-100 text-light-green p-3 mb-2"
                    >
                      Bağış Yap
                    </a>
                    <a
                      href="detayli-bilgi.html"
                      className="btn outline-dark-green w-100 p-3"
                    >
                      Detaylı Bilgi
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mb-lg-5 mb-4 w-auto">
                <div className="help-card">
                  <img
                    src="https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1490000/yemen-aa-1490262.jpg"
                    className="card-img-top "
                    alt="img"
                    style={{
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  />
                  <div className="card-body p-2">
                    <h5 className="help-title">Yetim Maaşı</h5>
                    <div className="row">
                      <div className="col-lg-12 position-relative mb-2">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="0"
                          />
                          <button className="btn border-0 position-absolute mini-green-btn mini-green-absolute">
                            TL
                          </button>
                        </div>
                      </div>
                    </div>
                    <a
                      href="kategoriler-1.html"
                      className="btn bg-dark-green w-100 text-light-green p-3 mb-2"
                    >
                      Bağış Yap
                    </a>
                    <a
                      href="detayli-bilgi.html"
                      className="btn outline-dark-green w-100 p-3"
                    >
                      Detaylı Bilgi
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="bagis-yap">
              <p className="m-0 p-0 montserrat fs-32 fw-700">Bereket Paketi</p>
              <p
                style={{
                  borderBottom: "2px solid #2B4141",
                  width: "100%",
                  height: "2px",
                  marginTop: "11px",
                }}
              ></p>
            </div>
            <div className="row my-lg-5">
              <div className="col-lg-4 col-md-6 col-12 mb-lg-5 mb-4 w-auto">
                <div className="help-card">
                  <img
                    src="https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1490000/yemen-aa-1490262.jpg"
                    className="card-img-top "
                    alt="img"
                    style={{
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  />
                  <div className="card-body p-2">
                    <h5 className="help-title">Bereket Paketi</h5>
                    <div className="row">
                      <div className="col-lg-12 position-relative mb-2">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="0"
                          />
                          <button className="btn border-0 position-absolute mini-green-btn mini-green-absolute">
                            TL
                          </button>
                        </div>
                      </div>
                    </div>
                    <a
                      href="kategoriler-1.html"
                      className="btn bg-dark-green w-100 text-light-green p-3 mb-2"
                    >
                      Bağış Yap
                    </a>
                    <a
                      href="detayli-bilgi.html"
                      className="btn outline-dark-green w-100 p-3"
                    >
                      Detaylı Bilgi
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mb-lg-5 mb-4 w-auto">
                <div className="help-card">
                  <img
                    src="https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1490000/yemen-aa-1490262.jpg"
                    className="card-img-top "
                    alt="img"
                    style={{
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  />
                  <div className="card-body p-2">
                    <h5 className="help-title">Bereket Paketi</h5>
                    <div className="row">
                      <div className="col-lg-12 position-relative mb-2">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="0"
                          />
                          <button className="btn border-0 position-absolute mini-green-btn mini-green-absolute">
                            TL
                          </button>
                        </div>
                      </div>
                    </div>
                    <a
                      href="kategoriler-1.html"
                      className="btn bg-dark-green w-100 text-light-green p-3 mb-2"
                    >
                      Bağış Yap
                    </a>
                    <a
                      href="detayli-bilgi.html"
                      className="btn outline-dark-green w-100 p-3"
                    >
                      Detaylı Bilgi
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mb-lg-5 mb-4 w-auto">
                <div className="help-card">
                  <img
                    src="https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1490000/yemen-aa-1490262.jpg"
                    className="card-img-top "
                    alt="img"
                    style={{
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  />
                  <div className="card-body p-2">
                    <h5 className="help-title">Bereket Paketi</h5>
                    <div className="row">
                      <div className="col-lg-12 position-relative mb-2">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="0"
                          />
                          <button className="btn border-0 position-absolute mini-green-btn mini-green-absolute">
                            TL
                          </button>
                        </div>
                      </div>
                    </div>
                    <a
                      href="kategoriler-1.html"
                      className="btn bg-dark-green w-100 text-light-green p-3 mb-2"
                    >
                      Bağış Yap
                    </a>
                    <a
                      href="detayli-bilgi.html"
                      className="btn outline-dark-green w-100 p-3"
                    >
                      Detaylı Bilgi
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="bagis-yap">
              <p className="m-0 p-0 montserrat fs-32 fw-700">
                Minik Dostlarımız
              </p>
              <p
                style={{
                  borderBottom: "2px solid #2B4141",
                  width: "100%",
                  height: "2px",
                  marginTop: "11px",
                }}
              ></p>
            </div>
            <div className="row my-lg-5">
              <div className="col-lg-4 col-md-6 col-12 mb-lg-5 mb-4 w-auto">
                <div className="help-card" style={{ width: " 18rem" }}>
                  <img
                    src="https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1490000/yemen-aa-1490262.jpg"
                    className="card-img-top "
                    alt="img"
                    style={{
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  />
                  <div className="card-body p-2">
                    <h5 className="help-title">Mama Paketi</h5>
                    <div className="row">
                      <div className="col-lg-6 position-relative mb-2">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="0"
                          />
                          <button className="btn border-0 position-absolute mini-green-btn mini-green-absolute">
                            TL
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-6 position-relative  mb-2">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="0"
                          />
                          <button className="btn border-0 position-absolute mini-green-btn mini-green-absolute">
                            Adet
                          </button>
                        </div>
                      </div>
                    </div>
                    <a
                      href="kategoriler-1.html"
                      className="btn bg-dark-green w-100 text-light-green p-3 mb-2"
                    >
                      Bağış Yap
                    </a>
                    <a
                      href="detayli-bilgi.html"
                      className="btn outline-dark-green w-100 p-3"
                    >
                      Detaylı Bilgi
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mb-lg-5 mb-4 w-auto">
                <div className="help-card" style={{ width: " 18rem" }}>
                  <img
                    src="https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1490000/yemen-aa-1490262.jpg"
                    className="card-img-top "
                    alt="img"
                    style={{
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  />
                  <div className="card-body p-2">
                    <h5 className="help-title">Mama Paketi</h5>
                    <div className="row">
                      <div className="col-lg-6 position-relative mb-2">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="0"
                          />
                          <button className="btn border-0 position-absolute mini-green-btn mini-green-absolute">
                            TL
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-6 position-relative  mb-2">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="0"
                          />
                          <button className="btn border-0 position-absolute mini-green-btn mini-green-absolute">
                            Adet
                          </button>
                        </div>
                      </div>
                    </div>
                    <a
                      href="kategoriler-1.html"
                      className="btn bg-dark-green w-100 text-light-green p-3 mb-2"
                    >
                      Bağış Yap
                    </a>
                    <a
                      href="detayli-bilgi.html"
                      className="btn outline-dark-green w-100 p-3"
                    >
                      Detaylı Bilgi
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mb-lg-5 mb-4 w-auto">
                <div className="help-card" style={{ width: " 18rem" }}>
                  <img
                    src="https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1490000/yemen-aa-1490262.jpg"
                    className="card-img-top "
                    alt="img"
                    style={{
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  />
                  <div className="card-body p-2">
                    <h5 className="help-title">Mama Paketi</h5>
                    <div className="row">
                      <div className="col-lg-6 position-relative mb-2">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="0"
                          />
                          <button className="btn border-0 position-absolute mini-green-btn mini-green-absolute">
                            TL
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-6 position-relative  mb-2">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="0"
                          />
                          <button className="btn border-0 position-absolute mini-green-btn mini-green-absolute">
                            Adet
                          </button>
                        </div>
                      </div>
                    </div>
                    <a
                      href="kategoriler-1.html"
                      className="btn bg-dark-green w-100 text-light-green p-3 mb-2"
                    >
                      Bağış Yap
                    </a>
                    <a
                      href="detayli-bilgi.html"
                      className="btn outline-dark-green w-100 p-3"
                    >
                      Detaylı Bilgi
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="bagis-yap">
              <p className="m-0 p-0 montserrat fs-32 fw-700">Bereket Paketi</p>
              <p
                style={{
                  borderBottom: "2px solid #2B4141",
                  width: "100%",
                  height: "2px",
                  marginTop: "11px",
                }}
              ></p>
            </div>
            <div className="row my-lg-5">
              <div className="col-lg-4 col-md-6 col-12 mb-lg-5 mb-4 w-auto">
                <div className="help-card">
                  <img
                    src="https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1490000/yemen-aa-1490262.jpg"
                    className="card-img-top "
                    alt="img"
                    style={{
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  />
                  <div className="card-body p-2">
                    <h5 className="help-title">Giyim Paketi</h5>
                    <div className="row">
                      <div className="col-lg-12 position-relative mb-2">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="0"
                          />
                          <button className="btn border-0 position-absolute mini-green-btn mini-green-absolute">
                            TL
                          </button>
                        </div>
                      </div>
                    </div>
                    <a
                      href="kategoriler-1.html"
                      className="btn bg-dark-green w-100 text-light-green p-3 mb-2"
                    >
                      Bağış Yap
                    </a>
                    <a
                      href="detayli-bilgi.html"
                      className="btn outline-dark-green w-100 p-3"
                    >
                      Detaylı Bilgi
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mb-lg-5 mb-4 w-auto">
                <div className="help-card">
                  <img
                    src="https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1490000/yemen-aa-1490262.jpg"
                    className="card-img-top "
                    alt="img"
                    style={{
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  />
                  <div className="card-body p-2">
                    <h5 className="help-title">Giyim Paketi</h5>
                    <div className="row">
                      <div className="col-lg-12 position-relative mb-2">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="0"
                          />
                          <button className="btn border-0 position-absolute mini-green-btn mini-green-absolute">
                            TL
                          </button>
                        </div>
                      </div>
                    </div>
                    <a
                      href="kategoriler-1.html"
                      className="btn bg-dark-green w-100 text-light-green p-3 mb-2"
                    >
                      Bağış Yap
                    </a>
                    <a
                      href="detayli-bilgi.html"
                      className="btn outline-dark-green w-100 p-3"
                    >
                      Detaylı Bilgi
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mb-lg-5 mb-4 w-auto">
                <div className="help-card">
                  <img
                    src="https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1490000/yemen-aa-1490262.jpg"
                    className="card-img-top "
                    alt="img"
                    style={{
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  />
                  <div className="card-body p-2">
                    <h5 className="help-title">Giyim Paketi</h5>
                    <div className="row">
                      <div className="col-lg-12 position-relative mb-2">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="0"
                          />
                          <button className="btn border-0 position-absolute mini-green-btn mini-green-absolute">
                            TL
                          </button>
                        </div>
                      </div>
                    </div>
                    <a
                      href="kategoriler-1.html"
                      className="btn bg-dark-green w-100 text-light-green p-3 mb-2"
                    >
                      Bağış Yap
                    </a>
                    <a
                      href="detayli-bilgi.html"
                      className="btn outline-dark-green w-100 p-3"
                    >
                      Detaylı Bilgi
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-12">
            <div className="card basket-all w-auto text-center">
              <ul className="list-group list-group-flush d-flex text-center justify-content-center">
                <li
                  className="list-group-item bg-light-green fs-18 fw-500 bagis-sepeti text-center d-flex align-items-center w-100 justify-content-center"
                  style={{
                    bordeEndEndRadius: "0px",
                    borderEndStartRadius: "0px",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                >
                  Bağış Sepetim
                </li>

                <li className="list-group-item p-4">
                  <p className="m-0 fw-600 fs-14 my-2 p-0 text-start">
                    EKMEK YARDIM
                  </p>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="m-0 text-color fs-14 fw-500">1.000 TL</p>
                    <button className="btn border-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM8.96963 8.96965C9.26252 8.67676 9.73739 8.67676 10.0303 8.96965L12 10.9393L13.9696 8.96967C14.2625 8.67678 14.7374 8.67678 15.0303 8.96967C15.3232 9.26256 15.3232 9.73744 15.0303 10.0303L13.0606 12L15.0303 13.9696C15.3232 14.2625 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2625 15.3232 13.9696 15.0303L12 13.0607L10.0303 15.0303C9.73742 15.3232 9.26254 15.3232 8.96965 15.0303C8.67676 14.7374 8.67676 14.2625 8.96965 13.9697L10.9393 12L8.96963 10.0303C8.67673 9.73742 8.67673 9.26254 8.96963 8.96965Z"
                          fill="#1C274C"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="d-flex align-items-center gap-1">
                    <p className="m-0 fs-13 fw-400">1.000 TL</p>
                    <p className="m-0 fs-13 fw-400">x 1 adet</p>
                  </div>
                </li>

                <li className="list-group-item p-4">
                  <p className="m-0 fw-600 fs-14 my-2 p-0 text-start">
                    EKMEK YARDIM
                  </p>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="m-0 text-color fs-14 fw-500">1.000 TL</p>
                    <button className="btn border-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM8.96963 8.96965C9.26252 8.67676 9.73739 8.67676 10.0303 8.96965L12 10.9393L13.9696 8.96967C14.2625 8.67678 14.7374 8.67678 15.0303 8.96967C15.3232 9.26256 15.3232 9.73744 15.0303 10.0303L13.0606 12L15.0303 13.9696C15.3232 14.2625 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2625 15.3232 13.9696 15.0303L12 13.0607L10.0303 15.0303C9.73742 15.3232 9.26254 15.3232 8.96965 15.0303C8.67676 14.7374 8.67676 14.2625 8.96965 13.9697L10.9393 12L8.96963 10.0303C8.67673 9.73742 8.67673 9.26254 8.96963 8.96965Z"
                          fill="#1C274C"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="d-flex align-items-center gap-1">
                    <p className="m-0 fs-13 fw-400">1.000 TL</p>
                    <p className="m-0 fs-13 fw-400">x 1 adet</p>
                  </div>
                </li>

                <li className="list-group-item ">
                  <p className="m-0 fw-600 fs-14 my-2 p-0 text-start">
                    EKMEK YARDIM
                  </p>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="m-0 text-color fs-14 fw-500">1.000 TL</p>
                    <button className="btn border-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM8.96963 8.96965C9.26252 8.67676 9.73739 8.67676 10.0303 8.96965L12 10.9393L13.9696 8.96967C14.2625 8.67678 14.7374 8.67678 15.0303 8.96967C15.3232 9.26256 15.3232 9.73744 15.0303 10.0303L13.0606 12L15.0303 13.9696C15.3232 14.2625 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2625 15.3232 13.9696 15.0303L12 13.0607L10.0303 15.0303C9.73742 15.3232 9.26254 15.3232 8.96965 15.0303C8.67676 14.7374 8.67676 14.2625 8.96965 13.9697L10.9393 12L8.96963 10.0303C8.67673 9.73742 8.67673 9.26254 8.96963 8.96965Z"
                          fill="#1C274C"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="d-flex align-items-center gap-1">
                    <p className="m-0 fs-13 fw-400">1.000 TL</p>
                    <p className="m-0 fs-13 fw-400">x 1 adet</p>
                  </div>
                </li>
                <button className="btn bg-light-green text-color w-100 rounded-0  p-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="p-0 m-0 fs-14 fw-500">Toplam</p>
                    <p className="m-0 p-0 fs-14 fw-600">3.000 TL</p>
                  </div>
                </button>
                <button
                  className="text-center d-flex align-items-center justify-content-center btn border-0 bg-dark-green text-nowrap  text-light-green w-100 h-100 fw-500 bagis-sepeti"
                  style={{
                    borderEndStartRadius: "10px",
                    borderEndEndRadius: "10px",
                    borderTopLeftRadius: "0px",
                    borderTopRightRadius: "0px",
                  }}
                >
                  Ödemeyi Tamamla
                </button>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BagisYap;
