import React, { useEffect, useState } from "react";
import "../assets/scss/style.scss";
import "../assets/scss/color.scss";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ShowPassword from "../Components/ShowPassword";
import { api } from "../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { handleLogin, handleLoginWithToken } from "../store/user";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast"
const Login = () => {
  const [activeTab, setActiveTab] = useState("1");
  const { pathname } = useLocation();
  const [loginForm, setLoginForm] = useState({ gsm: "", password: "" });
  const [registerForm, setRegisterForm] = useState({
    namesurname: "",
    gsm: "",
    password: "",
    passwordRepeat: "",
    gsm_ask: "",
  });
  const { login } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (pathname === "/login") {
      setActiveTab("1");
    } else {
      setActiveTab("2");
    }
  }, [pathname]);
  const handleSubmitLogin = (e) => {
    e.preventDefault();
    api()
      .post("login", loginForm)
      .then((res) => dispatch(handleLogin(res.data.data[0]))).catch((err) => {
        toast.error(err.response.data.message)
        console.log(err)
      })
  };
  useEffect(() => {
    if (login) {
      navigate("/");
    }
  }, [login]);
  const handleValueChange = (e) => {
    const { name, value } = e.target;
    setRegisterForm((val) => ({
      ...val,
      [name]:
        e.target.type === "number"
          ? Number(value)
          : e.target.type === "text"
          ? value.replace(/[^a-zA-ZığüşöçĞÜŞİÖÇ\s]/g, "")
          : value,
    }));
  };
  const handleRegisterFormSubmit = (e) => {
    e.preventDefault();
    if (
      registerForm.namesurname === "" ||
      registerForm.gsm === "" ||
      registerForm.password === ""
    ) {
      withReactContent(Swal).fire({
        icon: "error",
        title: "Lütfen tüm alanları doldurunuz",
      });
      return false;
    }
    if (registerForm.password !== registerForm.passwordRepeat) {
      withReactContent(Swal).fire({
        icon: "error",
        title: "Şifreler uyuşmuyor, lütfen geçerli bir değer giriniz",
      });
      return false;
    }
    api()
      .post("site-about/register", registerForm)
      .then((res) => {
        dispatch(handleLoginWithToken(res.data.token));
        withReactContent(Swal).fire({
          icon: "success",
          title: "Kayıt başarıyla alınmıştır.",
        });
      })
      .catch((err) => {
        withReactContent(Swal).fire({
          icon: "error",
          title: err.response.data.message,
        });
      });
  };
  return (
    <div className="login-register">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Kullanıcı Girişi | {process.env.REACT_APP_SITE_NAME}</title>
      </Helmet>

      <div className="giris-yap-content mx-auto mt-5">
        <div className="row">
          <div className="col-lg-6 col-12 p-0 m-0">
            <img
              src="giris-yap.png"
              alt="contentboximg"
              className="image-box"
            />
          </div>
          <div className="col-lg-6 col-12 mt-lg-0 mt-4 p-0 m-0">
            <div>
              <Nav tabs className="py-0 row align-items-center col-12">
                <NavItem className="col pe-0">
                  <Link
                    className={`nav-link ${activeTab === "1" && "active"}`}
                    to={"/login"}
                    style={{
                      padding: "20px",
                      textAlign: "center",
                      fontSize: "20px",
                      fontWeight: "600",
                      color: "black",
                    }}
                  >
                    Giriş Yap
                  </Link>
                </NavItem>
                <NavItem className="col pe-0">
                  <Link
                    className={`nav-link ${activeTab === "2" && "active"}`}
                    to={"/register"}
                    style={{
                      padding: "20px",
                      borderTopRightRadius: "15px",
                      textAlign: "center",
                      fontSize: "20px",
                      fontWeight: "600",
                      color: "black",
                    }}
                  >
                    Üye Ol
                  </Link>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab} className="mt-4">
                <TabPane tabId="1">
                  <form
                    action=""
                    onSubmit={handleSubmitLogin}
                    className="mt-4 forms"
                  >
                    <div className="mb-3 mx-auto w-75">
                      <label
                        htmlFor="phone-num"
                        className="form-label fs-16 fw-400"
                      >
                        Telefon Numarası
                      </label>
                      <input
                        type="number"
                        className="form-control number-only w-100 inputs"
                        id="phone-num"
                        placeholder="Telefon Numarası"
                        value={loginForm.gsm}
                        onChange={(e) =>
                          setLoginForm((val) => ({
                            ...val,
                            gsm:
                              e.target.value !== "" && Number(e.target.value),
                          }))
                        }
                      />
                    </div>
                    <div className="mb-3 mx-auto w-75 mt-4">
                      <label
                        htmlFor="passwordss"
                        className="form-label  fs-16 fw-400"
                      >
                        Şifre
                      </label>
                      <ShowPassword
                        value={loginForm.password}
                        className="form-control w-100 inputs"
                        onChange={(e) =>
                          setLoginForm((val) => ({
                            ...val,
                            password: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div className="ms-5 ps-3">
                      <Link
                        to={"/sifremi-unuttum"}
                        href="sifremi-unuttum.html"
                        className="me-3 text-decoration-none text-danger  fw-400 fs-14 sifremi-unuttum"
                      >
                        Şifremi Unuttum
                      </Link>
                    </div>
                    <button className="btn border-0 giris-yap-btn fw-700 fs-16 w-75 mx-auto mt-4 text-white">
                      Giriş Yap
                    </button>
                  </form>
                </TabPane>
                <TabPane tabId="2">
                  <form
                    action=""
                    onSubmit={handleRegisterFormSubmit}
                    className="row justify-content-center forms"
                  >
                    <div className="col-10">
                      <div className="row ">
                        <div className="col-12 mb-3 ">
                          <label
                            htmlFor="name-sur"
                            className="form-label fs-16 fw-400"
                          >
                            Ad Soyad
                          </label>
                          <input
                            type="text"
                            className="form-control text-only inputs"
                            id="name-sur"
                            placeholder="Ad Soyad"
                            name="namesurname"
                            value={registerForm.namesurname}
                            onChange={(e) => {
                              handleValueChange(e);
                            }}
                          />
                        </div>
                        <div className="col-12 mb-3   ">
                          <label
                            htmlFor="tephone"
                            className="form-label fs-16 fw-400"
                          >
                            Telefon Numarası
                          </label>
                          <input
                            type="number"
                            className="form-control number-only inputs"
                            id="tephone"
                            placeholder="Telefon Numarası"
                            name="gsm"
                            value={registerForm.gsm}
                            onChange={(e) =>
                              setRegisterForm((val) => ({
                                ...val,
                                gsm:
                                  e.target.value !== "" &&
                                  Number(e.target.value),
                              }))
                            }
                          />
                        </div>
                        <div className="col-lg-6 col-12 mb-3">
                          <label
                            htmlFor="sifre-1"
                            className="form-label  fs-16 fw-400"
                          >
                            Şifre
                          </label>
                          <ShowPassword
                            className="form-control  inputs"
                            name="password"
                            value={registerForm.password}
                            onChange={(e) => {
                              handleValueChange(e);
                            }}
                          />
                        </div>
                        <div className="col-lg-6 col-12   mb-3">
                          <label
                            htmlFor="sifre-2"
                            className="form-label fs-16 fw-400"
                          >
                            Şifre Tekrar
                          </label>
                          <ShowPassword
                            className="form-control  inputs"
                            name="passwordRepeat"
                            value={registerForm.passwordRepeat}
                            onChange={(e) => {
                              handleValueChange(e);
                            }}
                          />
                        </div>
                        <div className="ms-2   my-lg-0 my-3">
                          <Link
                            to={"/sifremi-unuttum"}
                            className="me-3 text-decoration-none text-danger  fw-400 fs-14 sifremi-unuttum"
                          >
                            Şifremi Unuttum
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="ms-3 ps-5 mx-auto d-flex align-items-start mt-4 flex-column">
                      <div className="form-check">
                        <input
                          className="form-check-input  "
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label fs-14 "
                          htmlFor="flexCheckDefault"
                        >
                          KVKK kapsamında Gizlilik Politikası’nı kabul ediyorum.
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input  "
                          type="checkbox"
                          value=""
                          id="flexCheckChecked1"
                          onChange={(e) => {
                            setRegisterForm((val) => ({
                              ...val,
                              gsm_ask: e.target.checked,
                            }));
                          }}
                        />
                        <label
                          className="form-check-label fs-14 "
                          htmlFor="flexCheckChecked1"
                        >
                          Bilgilendirme mesajları almak istiyorum.
                        </label>
                      </div>
                    </div>
                    <button className="btn border-0 giris-yap-btn fw-700 fs-16 w-75 mx-auto mt-4 text-white">
                      Üye Ol
                    </button>
                  </form>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
