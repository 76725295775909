import React from 'react'

const Support = () => {
  return (
    <div>
        <div className="support container">
        <p className="m-0 sup-title text-center mb-5 fw-32 fw-600 ">Projelere Destek Ol</p>
        <div className="row">
  
          <div className=" col-lg-12 mb-lg-5 mb-3">
            <div className="sup-card">
              <div className=" d-flex align-items-center justify-content-between flex-lg-row flex-column ">

                <div>
                  <img src="https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1784000/yemen-reu-1784326.jpg" alt="imagecontent" className="img-fluid" width="400"/>
                </div>

                <div className="d-flex flex-column align-items-lg-start align-items-center justify-content-between  h-100" style={{gap: "15px"}}>
                  <div className="p-2">
                    <p className="sup-title text-lg-start text-center m-0 p-0 fw-500 fs-20">Afrika Su Kuyusu - Hisseli</p>
                  </div>

                  <div className="d-flex gap-3">
                    <p className="m-0 bg-orange px-3 py-2 rounded text-white text-nowrap">Mevcut: 90 Hisse</p>
                    <p className="m-0 bg-green px-3 py-2 rounded text-white text-nowrap">Hedef: 100 Hisse</p>
                  </div>

                  <div className="w-100 py-4 ">
                    <div className="d-flex gap-3 align-items-center ">
                      <span className="fw-600 fs-20 text-color">25%</span>
                      <div className="progress col" role="progressbar" aria-label="Example with label" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                        <div className="progress-bar text-color fs-18 fw-600" style={{width: "25%"}}></div>
                      </div>
                    </div>
                  </div>
                  
                </div>
                
                <div className="d-flex flex-column p-3 boxes-content" >
                  <div className="position-relative">
                  <input type="text" className="form-control w-100 number-only" placeholder="1.000"/>
                  <button className="btn border-0 position-absolute mini-green-btn">TL</button>
                  </div>
                  <a href="kategoriler-1.html" className="btn border-0 bg-dark-green text-light-green fw-600 fs-18">Bağış Yap</a>
                </div>

              </div>
            </div>
          </div>
          <div className=" col-lg-12 mb-lg-5 mb-3">
            <div className="sup-card">
              <div className=" d-flex align-items-center justify-content-between flex-lg-row flex-column ">

                <div>
                  <img src="https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1784000/yemen-reu-1784326.jpg" alt="imagecontent" className="img-fluid" width="400"/>
                </div>

                <div className="d-flex flex-column align-items-lg-start align-items-center justify-content-between  h-100" style={{gap: "15px"}}>
                  <div className="p-2">
                    <p className="sup-title text-lg-start text-center m-0 p-0 fw-500 fs-20">Afrika Su Kuyusu - Hisseli</p>
                  </div>

                  <div className="d-flex gap-3">
                    <p className="m-0 bg-orange px-3 py-2 rounded text-white text-nowrap">Mevcut: 90 Hisse</p>
                    <p className="m-0 bg-green px-3 py-2 rounded text-white text-nowrap">Hedef: 100 Hisse</p>
                  </div>

                  <div className="w-100 py-4 ">
                    <div className="d-flex gap-3 align-items-center ">
                      <span className="fw-600 fs-20 text-color">25%</span>
                      <div className="progress col" role="progressbar" aria-label="Example with label" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                        <div className="progress-bar text-color fs-18 fw-600" style={{width: "25%"}}></div>
                      </div>
                    </div>
                  </div>
                  
                </div>
                
                <div className="d-flex flex-column p-3 boxes-content" >
                  <div className="position-relative">
                  <input type="text" className="form-control w-100 number-only" placeholder="1.000"/>
                  <button className="btn border-0 position-absolute mini-green-btn">TL</button>
                  </div>
                  <a href="kategoriler-1.html" className="btn border-0 bg-dark-green text-light-green fw-600 fs-18">Bağış Yap</a>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Support