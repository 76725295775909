import React, { Fragment, useEffect, useState } from "react";
import "../assets/scss/style.scss";
import "../assets/scss/color.scss";
import { api } from "../utils/api";
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.tr";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Helmet } from "react-helmet";

const Iletisim = () => {
  const [data, setData] = useState({});
  const [values, setValues] = useState({
    name: "",
    surname: "",
    mail: "",
    gsm: "",
    message: "",
  });
  const getContactData = () => {
    api()
      .get("site-about/contact")
      .then((res) => {
        setData(res.data.contact[0]);
      }).catch((err) => {
        console.log(err)
      })
  };
  useEffect(() => {
    getContactData();
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    api()
      .post("contact", values)
      .then((res) => {
        withReactContent(Swal).fire({
          icon: "success",
          title: "İletişim isteğiniz başarıyla alındı",
        });
        setValues({
          name: "",
          surname: "",
          mail: "",
          gsm: "",
          message: "",
        });
      })
      .catch((err) => {
        console.log(err);
        withReactContent(Swal).fire({
          icon: "error",
          title: err.response.data.message,
        });
      });
  };
  const handleValueChange = (e) => {
    const { name, value } = e.target;
    setValues((val) => ({
      ...val,
      [name]:
        e.target.type === "number"
          ? Number(value)
          : e.target.type === "text"
          ? value.replace(/[^a-zA-ZığüşöçĞÜŞİÖÇ\s]/g, "")
          : value,
    }));
  };
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>İletişime Geç | {process.env.REACT_APP_SITE_NAME}</title>
      </Helmet>

      <div className="login-register px-lg-0 container">
        <div className="row d-flex justify-content-lg-center">
          <div className="col-lg-10 col-12 ">
            <div className="giris-yap-content mx-auto mt-5 container">
              <div className="row">
                <div className="col-lg-6 col-12 p-0 m-0">
                  <img
                    src="giris-yap.png"
                    alt="contentboximg"
                    className="image-box"
                    style={{ maxHeight: "575px" }}
                  />
                </div>
                <div className="col-lg-6 col-12 mt-lg-0 mt-4 p-0 m-0">
                  <p className="text-center fw-600 fs-20 text-color p-4">
                    Bize Ulaşın
                  </p>
                  <form
                    action=""
                    onSubmit={handleSubmit}
                    className="iletisim-form px-0 px-lg-2"
                  >
                    <div className="row w-100 mx-auto">
                      <div className="col-lg-6 col-12 mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="adiniz"
                            className="form-label fw-400 fs-16"
                          >
                            Adınız
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="adiniz"
                            placeholder="Adınız"
                            name="name"
                            value={values.name}
                            onChange={(e) => handleValueChange(e)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="soyadiniz"
                            className="form-label fw-400 fs-16"
                          >
                            Soyadınız
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="soyadiniz"
                            placeholder="Soyadınız"
                            name="surname"
                            value={values.surname}
                            onChange={(e) => handleValueChange(e)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="eposta"
                            className="form-label fw-400 fs-16"
                          >
                            E-Posta
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="eposta"
                            placeholder="E-Posta Adresiniz"
                            name="mail"
                            value={values.mail}
                            onChange={(e) => handleValueChange(e)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="phone-number"
                            className="form-label fw-400 fs-16"
                          >
                            Telefon
                          </label>
                          <Cleave
                            type="tel"
                            className="form-control"
                            placeholder="555 555 55 55"
                            options={{ phone: true, phoneRegionCode: "TR" }}
                            id="phone-number"
                            name="gsm"
                            value={values.gsm}
                            onChange={(e) => handleValueChange(e)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12  mb-4">
                        <label
                          className="form-label fw-400 fs-16"
                          htmlFor="textareas"
                        >
                          Mesajınız
                        </label>
                        <div className="">
                          <textarea
                            className="form-control"
                            placeholder="Mesajınız"
                            rows={3}
                            id="textareas"
                            name="message"
                            value={values.message}
                            onChange={(e) => handleValueChange(e)}
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-lg-12 mb-lg-2 mb-5">
                        <button
                          type="submit"
                          className="btn border-0 gonder-btn  w-100 fw-700 fs-16"
                        >
                          Gönder
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="login-register container px-lg-0  mt-5">
          <div className="row justify-content-lg-center">
            <div className="col-lg-10 col-12">
              <div className="d-flex w-100 justify-content-lg-between gap-5 flex-wrap">
                <div className="col-lg-5 col-12 mb-lg-0 mb-3">
                  <div className="box-content">
                    <div className="d-flex align-items-center flex-lg-row flex-column justify-content-lg-between justify-content-center gap-lg-4 gap-4 p-4 px-5  ">
                      <div className="dark-green-circle d-flex align-items-center justify-content-center rounded-circle">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="56"
                          height="56"
                          viewBox="0 0 56 56"
                          fill="none"
                        >
                          <path
                            d="M48.1133 19.7167C45.6633 8.93671 36.26 4.08337 28 4.08337C28 4.08337 28 4.08337 27.9766 4.08337C19.74 4.08337 10.3133 8.91337 7.86331 19.6934C5.13331 31.7334 12.5066 41.93 19.18 48.3467C21.6533 50.7267 24.8266 51.9167 28 51.9167C31.1733 51.9167 34.3466 50.7267 36.7966 48.3467C43.47 41.93 50.8433 31.7567 48.1133 19.7167ZM28 31.4067C23.94 31.4067 20.65 28.1167 20.65 24.0567C20.65 19.9967 23.94 16.7067 28 16.7067C32.06 16.7067 35.35 19.9967 35.35 24.0567C35.35 28.1167 32.06 31.4067 28 31.4067Z"
                            fill="#E1FDAE"
                          />
                        </svg>
                      </div>
                      <div className="d-flex flex-column">
                        <p className="p-0 m-0 mb-3 text-lg-start text-center">
                          GENEL MERKEZ
                        </p>
                        <p
                          className="m-0 p-0 fs-17 text-lg-start text-center"
                          dangerouslySetInnerHTML={{
                            __html: data?.first_adress,
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-12 mb-lg-0 mb-3">
                  <div className="box-content">
                    <div className="d-flex align-items-center flex-lg-row flex-column justify-content-lg-between justify-content-center gap-lg-4 gap-4 p-4 px-5  ">
                      <div className="dark-green-circle d-flex align-items-center justify-content-center rounded-circle">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="56"
                          height="56"
                          viewBox="0 0 56 56"
                          fill="none"
                        >
                          <path
                            d="M6.41661 43.5167C5.45994 43.5167 4.66661 42.7233 4.66661 41.7667V28.4667C4.54994 22.1433 6.90661 16.17 11.2933 11.69C15.6799 7.23333 21.5599 4.78333 27.8833 4.78333C40.8099 4.78333 51.3333 15.3067 51.3333 28.2333V41.5333C51.3333 42.49 50.5399 43.2833 49.5833 43.2833C48.6266 43.2833 47.8333 42.49 47.8333 41.5333V28.2333C47.8333 17.2433 38.8966 8.28333 27.8833 8.28333C22.4933 8.28333 17.4999 10.36 13.7899 14.14C10.0566 17.9433 8.07328 23.0067 8.16661 28.42V41.7433C8.16661 42.7233 7.39661 43.5167 6.41661 43.5167Z"
                            fill="#E1FDAE"
                          />
                          <path
                            d="M13.86 29.05H13.5566C8.65663 29.05 4.66663 33.04 4.66663 37.94V42.3267C4.66663 47.2267 8.65663 51.2167 13.5566 51.2167H13.86C18.76 51.2167 22.75 47.2267 22.75 42.3267V37.94C22.75 33.04 18.76 29.05 13.86 29.05Z"
                            fill="#E1FDAE"
                          />
                          <path
                            d="M42.4433 29.05H42.14C37.24 29.05 33.25 33.04 33.25 37.94V42.3267C33.25 47.2267 37.24 51.2167 42.14 51.2167H42.4433C47.3433 51.2167 51.3333 47.2267 51.3333 42.3267V37.94C51.3333 33.04 47.3433 29.05 42.4433 29.05Z"
                            fill="#E1FDAE"
                          />
                        </svg>
                      </div>{" "}
                      <div className="d-flex flex-column">
                        <p className="p-0 m-0 mb-3 text-lg-start text-center">
                          ÇAĞRI MERKEZİ
                        </p>
                        <article className="m-0 p-0 fs-17 text-lg-start text-center">
                          {data?.first_contact}
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-12 mb-lg-0 mb-3">
                  <div className="box-content">
                    <div className="d-flex align-items-center flex-lg-row flex-column justify-content-lg-between justify-content-center gap-lg-4 gap-4 p-4 px-5  ">
                      <div className="dark-green-circle d-flex align-items-center justify-content-center rounded-circle">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="56"
                          height="56"
                          viewBox="0 0 56 56"
                          fill="none"
                        >
                          <path
                            d="M48.1133 19.7167C45.6633 8.93671 36.26 4.08337 28 4.08337C28 4.08337 28 4.08337 27.9766 4.08337C19.74 4.08337 10.3133 8.91337 7.86331 19.6934C5.13331 31.7334 12.5066 41.93 19.18 48.3467C21.6533 50.7267 24.8266 51.9167 28 51.9167C31.1733 51.9167 34.3466 50.7267 36.7966 48.3467C43.47 41.93 50.8433 31.7567 48.1133 19.7167ZM28 31.4067C23.94 31.4067 20.65 28.1167 20.65 24.0567C20.65 19.9967 23.94 16.7067 28 16.7067C32.06 16.7067 35.35 19.9967 35.35 24.0567C35.35 28.1167 32.06 31.4067 28 31.4067Z"
                            fill="#E1FDAE"
                          />
                        </svg>
                      </div>
                      <div className="d-flex flex-column">
                        <p className="p-0 m-0 mb-3 text-lg-start text-center">
                          AKADEMİ
                        </p>
                        <p
                          className="m-0 p-0 fs-17 text-lg-start text-center"
                          dangerouslySetInnerHTML={{
                            __html: data?.second_adress,
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-12 mb-lg-0 mb-3">
                  <div className="box-content">
                    <div className="d-flex align-items-center flex-lg-row flex-column justify-content-lg-between justify-content-center gap-lg-4 gap-4 p-4 px-5  ">
                      <div className="dark-green-circle d-flex align-items-center justify-content-center rounded-circle">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="56"
                          height="56"
                          viewBox="0 0 56 56"
                          fill="none"
                        >
                          <path
                            d="M6.41661 43.5167C5.45994 43.5167 4.66661 42.7233 4.66661 41.7667V28.4667C4.54994 22.1433 6.90661 16.17 11.2933 11.69C15.6799 7.23333 21.5599 4.78333 27.8833 4.78333C40.8099 4.78333 51.3333 15.3067 51.3333 28.2333V41.5333C51.3333 42.49 50.5399 43.2833 49.5833 43.2833C48.6266 43.2833 47.8333 42.49 47.8333 41.5333V28.2333C47.8333 17.2433 38.8966 8.28333 27.8833 8.28333C22.4933 8.28333 17.4999 10.36 13.7899 14.14C10.0566 17.9433 8.07328 23.0067 8.16661 28.42V41.7433C8.16661 42.7233 7.39661 43.5167 6.41661 43.5167Z"
                            fill="#E1FDAE"
                          />
                          <path
                            d="M13.86 29.05H13.5566C8.65663 29.05 4.66663 33.04 4.66663 37.94V42.3267C4.66663 47.2267 8.65663 51.2167 13.5566 51.2167H13.86C18.76 51.2167 22.75 47.2267 22.75 42.3267V37.94C22.75 33.04 18.76 29.05 13.86 29.05Z"
                            fill="#E1FDAE"
                          />
                          <path
                            d="M42.4433 29.05H42.14C37.24 29.05 33.25 33.04 33.25 37.94V42.3267C33.25 47.2267 37.24 51.2167 42.14 51.2167H42.4433C47.3433 51.2167 51.3333 47.2267 51.3333 42.3267V37.94C51.3333 33.04 47.3433 29.05 42.4433 29.05Z"
                            fill="#E1FDAE"
                          />
                        </svg>
                      </div>{" "}
                      <div className="d-flex flex-column">
                        <p className="p-0 m-0 mb-3 text-lg-start text-center">
                          E-POSTA
                        </p>
                        <a
                          href={`mailto:${data.second_contact}`}
                          className="m-0 p-0 fs-17 text-lg-start text-center text-decoration-none text-dark"
                        >
                          {data.second_contact}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Iletisim;
