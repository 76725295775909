import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../utils/api";
import Aside from "./Components/Aside";
import { Helmet } from "react-helmet";

const pageLinks = [
  {
    id: 1,
    slug: "hakkimizda",
    url: "hakkimizda",
    title: "Hakkımızda",
  },
  {
    id: 2,
    title: "Değerlerimiz",
    slug: "degerlerimiz",
    url: "degerlerimiz",
  },
  {
    id: 3,
    title: "İnsan Kaynakları",
    slug: "insan-kaynaklari",
    url: "insan-kaynaklari",
  },
  {
    id: 4,
    title: "Basın Odası",
    slug: "basin-odasi",
    url: "basin-odasi",
  },
  {
    id: 5,
    title: "Yönetim Kurulu",
    slug: "yonetim-kurulu",
    url: "yonetim-kurulu",
  },
  {
    id: 6,
    title: "Gizlilik Politikası",
    slug: "gizlilik",
    url: "gizlilik",
  },
];

const Hakkimizda = () => {
  const { slug } = useParams();
  const [data, setData] = useState([]);
  const [pageData, setPageData] = useState({title:""});
  const navigate = useNavigate();
  const getPageContent = (slug) => {
    api()
      .get(`site-about/${slug}`)
      .then((res) => {
        setData(res.data.data[0]);
      });
  };
  useEffect(() => {
    const findSlug = pageLinks.find((link) => link.slug === slug);
    if (findSlug) {
      getPageContent(findSlug.url);
      setPageData(findSlug);
    } else {
      navigate("/");
    }
  }, [slug]);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {pageData?.title} | {process.env.REACT_APP_SITE_NAME}
        </title>
      </Helmet>
      {/* green bg title */}
      <div className="bg-dark-green-section mb-lg-0 mb-5">
        <p className="m-0 fw-700 fs-32 text-light-green text-center">
          {pageData.title}
        </p>
      </div>
      {/* green bg title */}

      <div className="kurumsal-container">
        <div className="row ">
          <div className="col-lg-3 col-12 ">
            <Aside />
          </div>
          <div className="col-lg-9 col-12  mt-lg-0 mt-5">
            <article
              dangerouslySetInnerHTML={{ __html: data.description }}
            ></article>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hakkimizda;
